<template>
  <div>
    <div class="TipTapWrap" :class="disabled ? 'disabled' : ''">
      <div class="mb-3 flex justify-end" v-if="!noButtons">
        <i
          :class="{ 'is-active': editor && editor.isActive('bold') }"
          v-tooltip="'Toggle Bold'"
          @click="addonClick('toggleBold')"
          class="icofont-bold button-i"
        ></i>
        <i
          :class="{ 'is-active': editor && editor.isActive('italic') }"
          v-tooltip="'Toggle Italic'"
          @click="addonClick('toggleItalic')"
          class="icofont-italic button-i"
        ></i>
        <i
          :class="{ 'is-active': editor && editor.isActive('strike') }"
          v-tooltip="'Toggle Strike'"
          @click="addonClick('toggleStrike')"
          class="icofont-strike-through button-i"
        ></i>
        <i
          v-tooltip="'Insert Horizontal Rule'"
          @click="addonClick('setHorizontalRule')"
          class="icofont-minus button-i"
        ></i>
        <i
          v-tooltip="'Undo'"
          @click="addonClick('undo')"
          class="icofont-undo button-i"
        ></i>
        <i
          v-tooltip="'Redo'"
          @click="addonClick('redo')"
          class="icofont-redo button-i"
        ></i>
        <i
          :class="{ 'is-active': editor && editor.isActive('blockquote') }"
          v-tooltip="'Toggle Blockquote'"
          @click="addonClick('toggleBlockquote')"
          class="icofont-quote-right button-i"
        ></i>
        <i
          :class="{ 'is-active': editor && editor.isActive('codeblock') }"
          v-tooltip="'Toggle CodeBlock'"
          @click="addonClick('toggleCodeBlock')"
          class="icofont-code-alt button-i"
        ></i>
        <i
          :class="{ 'is-active': editor && editor.isActive('bulletlist') }"
          v-tooltip="'Toggle BulletList'"
          @click="addonClick('toggleBulletList')"
          class="icofont-listine-dots button-i"
        ></i>
        <i
          :class="{ 'is-active': editor && editor.isActive('orderedlist') }"
          v-tooltip="'Toggle OrderedList'"
          @click="addonClick('toggleOrderedList')"
          class="icofont-listing-number button-i"
        ></i>
        <i
          :class="{ 'is-active': editor && editor.isActive('tasklist') }"
          v-tooltip="'Toggle CheckList'"
          @click="addonClick('toggleTaskList')"
          class="icofont-listing-number button-i"
        ></i>
        <i
          :class="{
            'is-active': editor && editor.isActive('heading', { level: 3 })
          }"
          v-tooltip="'Toggle Heading 3'"
          @click="addonClick('toggleHeading', { level: 3 })"
          class="icofont-heading button-i"
        ></i>
      </div>
      <div
        ref="editorWrap"
        :style="minHeight ? 'min-height: ' + minHeight + 'px' : ''"
      >
        <EditorContent
          class="editorContent"
          :class="hasValue || focused ? 'has-content' : ''"
          @input.stop
          :editor="editor"
          :style="
            [
              maxRows ? 'max-height:' + maxRows * 36 + 'px' : '',
              minRows ? 'min-height:' + maxRows * 36 + 'px' : ''
            ].join(';')
          "
        />
        <label v-if="label" v-html="label"></label>
      </div>
    </div>
    <div class="error-text ml-2 mt-2" v-if="error">
      <i class="icofont-error mr-2"></i>{{ error }}
    </div>
  </div>
</template>

<script>
  import { Editor, EditorContent } from '@tiptap/vue-3'
  import StarterKit from '@tiptap/starter-kit'
  import TaskList from '@tiptap/extension-task-list'
  import TaskItem from '@tiptap/extension-task-item'
  import Link from '@tiptap/extension-link'

  export default {
    name: 'TipTap',
    components: { EditorContent },
    props: [
      'value',
      'disabled',
      'error',
      'noButtons',
      'autofocus',
      'maxRows',
      'minRows',
      'label'
    ],
    data: () => ({
      editor: null,
      throttleInputEvent: null,
      focused: false,
      minHeight: 0
    }),
    watch: {
      value(val) {
        if (this.editor.getHTML() != val) this.editor.commands.setContent(val)
      },
      disabled(val) {
        this.editor.setEditable(!val)
      }
    },
    mounted() {
      const _this = this
      this.editor = new Editor({
        autofocus: this.autofocus,
        content: this.value,
        extensions: [StarterKit, TaskList, TaskItem, Link],
        onUpdate: () => {
          clearTimeout(this.throttleInputEvent)
          _this.throttleInputEvent = setTimeout(() => _this.onUpdate(), 300)
        },
        onFocus() {
          _this.focused = true
        },
        onBlur() {
          _this.onUpdate()
          _this.focused = false
        }
      })
      this.editor.setEditable(!this.disabled)
    },
    beforeUnmount() {
      this.editor.destroy()
    },
    computed: {
      hasValue() {
        return !!this.value && this.value != '<p></p>'
      }
    },
    methods: {
      addonClick(command, arg) {
        if (this.disabled) return
        this.editor.commands[command](arg)
        this.editor.commands.focus()
      },
      onUpdate() {
        this.$emit('input', this.editor.getHTML())
      },
      focus() {
        this.editor.commands.focus()
      }
    }
  }
</script>

<style>
  .disabled .button-i {
    pointer-events: none;
  }
  .TipTapWrap {
    position: relative;
  }
  .TipTapWrap i {
    padding-right: 8px;
  }
  .TipTapWrap .is-active {
    color: black;
  }
  .editorContent .ProseMirror {
    font-size: 15px;
    font-weight: 400;
    padding: 7px;
    border: none;
    transition: color 0.3s ease-out;
    outline: none;
  }
  .editorContent {
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  .white-text:not(~ .black-text) .editorContent {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.7);
  }
  .black-text .editorContent {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.7);
  }
  .editorContent ~ label {
    position: absolute;
    left: 8px;
    width: calc(100% - 16px);
    top: 30px;
    color: rgba(0, 0, 0, 0.45);
    transition: 0.3s;
    z-index: -1;
    letter-spacing: 0.5px;
  }
  .editorContent:focus ~ label,
  .has-content.editorContent ~ label {
    top: 2px;
    left: 1px;
    font-size: 12px;
    color: #000000;
    transition: 0.3s;
  }
  .white-text:not(~ .black-text) .editorContent ~ label {
    color: rgba(255, 255, 255, 0.45);
  }
  .editorContent.white-text:not(~ .black-text):focus ~ label,
  .has-content.white-text:not(~ .black-text).editorContent ~ label {
    color: #ffffff;
  }
  ul[data-type='taskList'] li {
    display: flex;
  }
  ul[data-type='taskList'] {
    padding-left: 4px;
  }
</style>
