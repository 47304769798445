<template>
  <div>
    <form @submit.prevent class="flex wrap" style="overflow: hidden">
      <h6 v-if="title">{{ title }}</h6>
      <div
        v-for="(field, key) of value"
        :key="key"
        class="pa-2 xs12"
        :class="field.class ? field.class : ''"
        v-show="field.visible === undefined || field.visible"
      >
        <template v-if="field.type == 'select'">
          <Select
            @input="inputs[key] = $event"
            :value="inputs[key]"
            :items="field.options"
            :label="value[key].label"
            :name="key"
            class="width100"
            :disabled="disabled"
            :error="errors[key]"
            :ref="key"
          ></Select>
        </template>
        <div v-else-if="field.type == 'datetime'">
          <DateTimePicker
            @input="inputs[key] = $event"
            :value="inputs[key]"
            :label="value[key].label"
            :disabled="disabled"
            :error="errors[key]"
            :viewTime="value[key].viewTime"
            :ref="key"
          ></DateTimePicker>
        </div>
        <div v-else-if="field.type == 'textarea'">
          <TipTap
            @input="inputs[key] = $event"
            :value="inputs[key]"
            :label="value[key].label"
            :disabled="disabled"
            :error="errors[key]"
            :ref="key"
          ></TipTap>
        </div>
        <div v-else-if="field.type == 'datetimerange'">
          <DateTimePicker
            :range="true"
            @input="inputs[key] = $event"
            :value="inputs[key]"
            :label="value[key].label"
            :disabled="disabled"
            :error="errors[key]"
            :viewTime="value[key].viewTime"
            :ref="key"
          ></DateTimePicker>
        </div>
        <div v-else-if="field.type == 'button'">
          <Button
            :disabled="disabled"
            :ref="key"
            v-html="value[key].label"
            @click="value[key].click"
            variant="smooth error"
          ></Button>
        </div>
        <Input
          v-else
          @input="textInput($event, key)"
          :value="inputs[key]"
          :name="key"
          :type="field.type"
          :label="value[key].label"
          class="width100"
          :disabled="disabled"
          :error="errors[key]"
          @keydown.stop
          :ref="key"
        />
      </div>
      <div class="flex xs12 pt-3">
        <Button
          class="ma-4"
          variant="text"
          v-if="allowCancel"
          @click.stop="$emit('cancel')"
          :disabled="disabled"
        >
          {{
            type == 'yesno'
              ? 'No'
              : hasChanges && type == 'edit'
              ? 'Discard changes'
              : 'Cancel'
          }}
        </Button>
        <div class="xs12"></div>
        <Button
          v-if="type == 'create'"
          type="submit"
          name="submit"
          class="ma-4"
          variant="smooth primary"
          @click.stop="update"
          :disabled="disabled"
          :loading="loading"
        >
          Create
        </Button>
        <Button
          v-else-if="type == 'edit'"
          type="submit"
          name="submit"
          class="ma-4"
          variant="smooth primary"
          @click.stop="update"
          :disabled="disabled"
          :loading="loading"
        >
          Save
        </Button>
        <Button
          v-else-if="type == 'yesno'"
          type="submit"
          name="submit"
          class="ma-4"
          variant="smooth primary"
          @click.stop="update"
          :disabled="disabled"
          :loading="loading"
        >
          Yes
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
  import Input from './Input.vue'
  import Select from './Select.vue'
  import Button from './Button.vue'
  import TipTap from './TipTap.vue'
  import DateTimePicker from './DateTimePicker.vue'

  export default {
    name: 'DataForm',
    components: { Input, Select, DateTimePicker, Button, TipTap },
    props: ['value', 'type', 'allowCancel', 'title', 'loading', 'disabled'],
    mounted() {
      this.reset()
    },
    watch: {
      value() {
        this.reset()
      },
      type() {
        this.reset()
      },
      inputs: {
        deep: true,
        handler() {
          for (const key in this.inputs) {
            if (this.inputs[key] != this.value[key].default) {
              return (this.hasChanges = true)
            }
          }
          this.hasChanges = false
        }
      },
      hasChanges(val) {
        this.$emit('updateHasChanges', val)
      }
    },
    data: () => ({
      inputs: {},
      errors: {},
      hasChanges: false
    }),
    methods: {
      async reset() {
        this.inputs = {}
        for (const key in this.value) {
          if (this.value[key].default) {
            this.inputs[key] = this.value[key].default
          } else if (['button'].indexOf(this.value[key].type) < 0) {
            this.inputs[key] = ''
          }
        }
        this.hasChanges = false
        await this.$nextTick()
        if (this.$refs[Object.keys(this.inputs)[0]]) {
          this.$refs[Object.keys(this.inputs)[0]][0].focus()
        }
      },
      textInput(val, key) {
        this.inputs[key] = val
      },
      update() {
        if (this.validate()) this.$emit('update', this.inputs)
      },
      validate() {
        const errors = {}
        for (const key in this.value) {
          const field = this.value[key]
          if (field.validate) {
            const validation = field.validate(this.inputs[key])
            if (validation !== true) errors[key] = validation
          }
        }
        this.errors = errors
        return !Object.keys(errors).length
      }
    }
  }
</script>

<style scoped></style>
