<template>
  <div>
    <div class="glass-panel pa-3 ma-3">Agenda</div>
    <div class="glass-panel pa-3 ma-3">Time summaries</div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    components: {},
    data: () => ({}),
    watch: {},
    mounted() {},
    computed: {},
    methods: {}
  }
</script>

<style scoped></style>
