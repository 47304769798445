<template>
  <div class="sideBar pt-3" :style="'max-width:' + width + 'px'" v-if="value">
    <div class="flex justify-center my-4">
      <img src="../../assets/logo.png" />
    </div>
    <div class="flex">
      <Button
        v-tooltip="'Start new time entry'"
        variant="primary smooth width100"
        class="mx-3"
        @click="createNewTimeEntry"
      >
        <i class="icofont-play-alt-1 mr-3"></i> Start new timer
      </Button>
    </div>
    <ActiveTimeEntry
      v-for="entry in activeTimeEntries"
      :key="entry.id"
      class="pa-3 mx-3 mt-4"
      :value="entry"
    >
    </ActiveTimeEntry>
    <div class="mt-4"></div>

    <SideBarLink
      route="marketing"
      text="Marketing"
      icon="icofont-duotone icofont-info-circle"
    ></SideBarLink>

    <SideBarLink
      route="home"
      text="Home"
      icon="icofont-duotone icofont-home"
    ></SideBarLink>

    <SideBarLink
      route="calendar"
      icon="icofont-duotone icofont-calendar"
      text="Calendar"
    >
    </SideBarLink>

    <div class="line"></div>

    <SideBarLink
      route="tasks"
      icon="icofont-duotone icofont-file-check"
      text="Tasks"
    ></SideBarLink>
    <SideBarLink
      route="reports"
      icon="icofont-duotone icofont-logs"
      text="Reports"
    ></SideBarLink>
    <SideBarLink
      route="projects"
      icon="icofont-duotone icofont-category"
      text="Projects"
    ></SideBarLink>

    <div class="line"></div>

    <SideBarLink
      route="settings"
      icon="icofont-duotone icofont-cogs"
      text="Settings"
    ></SideBarLink>
    <SideBarLink
      route="signout"
      icon="icofont-duotone icofont-exit"
      text="Sign out"
    ></SideBarLink>
  </div>
</template>

<script>
  import moment from 'moment'
  import ActiveTimeEntry from './ActiveTimeEntry.vue'
  import Button from '../common/Button.vue'
  import SideBarLink from './SideBarLink.vue'
  export default {
    name: 'SideBar',
    components: {
      SideBarLink,
      ActiveTimeEntry,
      Button
    },
    props: ['value', 'width'],
    computed: {
      activeTimeEntries() {
        return this.$store.getters.activeTimeEntries
      },
      user() {
        return this.$store.getters.user
      }
    },
    methods: {
      async createNewTimeEntry() {
        this.$store.dispatch('createTimeEntry', {
          start: moment().format()
        })
      }
    }
  }
</script>

<style scoped>
  img {
    height: 32px;
  }
  .sideBar {
    position: relative;
    border-top: none;
    /* border-right: 2px solid rgba(255, 255, 255, 0.4); */
  }
  .sideBar > .line {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  .sideBar > a:nth-child(1) {
    margin-top: 0px;
  }
  .sideBar > a {
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.15s ease;
    border-radius: 8px;
    text-decoration: none;
  }
  .sideBar > a:visited,
  .sideBar > a:link {
    color: black;
  }
  .sideBar > a:hover:not(.line) {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .profileButton {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 16px);
  }
  .itemActive {
    background-color: rgba(0, 0, 0, 0.1);
    color: #1a5989 !important;
  }
</style>
