<template>
  <div class="expand">
    <Transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <slot></slot>
    </Transition>
  </div>
</template>
<script>
  export default {
    props: ['width'],
    methods: {
      enter(element) {
        const height = getComputedStyle(element).height
        element.style.height = height
        element.style.position = 'absolute'
        element.style.visibility = 'hidden'
        element.style.width = 'auto'
        let width = this.width || getComputedStyle(element).width
        element.style.height = null
        element.style.position = null
        element.style.visibility = null
        element.style.width = 0
        getComputedStyle(element).width
        requestAnimationFrame(() => {
          element.style.width = width
        })
      },
      afterEnter(element) {
        element.style.width = this.width || 'auto'
      },
      leave(element) {
        const width = getComputedStyle(element).width
        element.style.width = width
        getComputedStyle(element).width
        requestAnimationFrame(() => {
          element.style.width = 0
        })
      }
    }
  }
</script>
<style scoped>
  .expand {
    .expand-enter-active,
    .expand-leave-active {
      transition: width 0.4s ease-in-out, opacity 0.6s ease;
      overflow: hidden;
    }
    .expand-enter,
    .expand-leave-to {
      width: 0;
      opacity: 0;
    }
    * {
      will-change: width;
      transform: translateZ(0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }
</style>
