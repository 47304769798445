import { Codemirror } from 'vue-codemirror';
<template>
  <Transition>
    <div class="dialog" v-if="value" @mousedown="onBackdropClick">
      <div
        class="dialog-box glass-light-2"
        :style="'width:' + (width ? width : '50%')"
        :class="{ 'pa-4': !noPadding, shake: shakeAnimate }"
        @mousedown.stop
      >
        <div class="dialog-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
  export default {
    name: 'Dialog',
    props: ['value', 'noPadding', 'persistent', 'width'],
    data: () => ({
      shakeAnimate: false
    }),
    computed: {},
    watch: {
      value(showDialog) {
        if (showDialog) {
          window.addEventListener('keydown', this.keypressListener)
        } else {
          window.removeEventListener('keydown', this.keypressListener)
        }
      }
    },
    async mounted() {},
    components: {},
    methods: {
      onBackdropClick() {
        if (!this.persistent) {
          this.$emit('close')
        } else {
          this.shakeAnimate = true
          setTimeout(() => {
            this.shakeAnimate = false
          }, 620)
        }
      },
      keypressListener(e) {
        if (e.keyCode == 27) {
          this.$emit('close')
        }
      }
    }
  }
</script>

<style scoped>
  .dialog {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .dialog-box {
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .dialog-content {
    max-height: 90vh;
    overflow: auto;
  }

  .shake {
    animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
