<template>
  <div>
    <Datepicker
      v-model="valueHold"
      format="yyyy-MM-dd HH:mm - yyyy-MM-dd HH:mm"
      :range="range"
      @update:model-value="selectDateTime"
      :disabled="disabled"
      :flow="viewTime ? ['time', 'calendar'] : []"
    >
      <template v-slot:trigger>
        <Input
          :value="formattedTime"
          @focus="menu = true"
          @blur="blur"
          @input="input"
          class="width100"
          inputClass="cursor-pointer"
          type="text"
          :name="name"
          :label="label"
          :readonly="true"
          :disabled="disabled"
          :error="error"
          ref="input"
        />
      </template>
      <template #action-row="{ selectDate }">
        <div class="flex justify-end">
          <Button class="ma-4" variant="smooth primary" @click="selectDate">
            Select
          </Button>
        </div>
      </template>
    </Datepicker>
  </div>
</template>

<script>
  import moment from 'moment'
  import Datepicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import Input from './Input.vue'
  import Button from './Button.vue'

  export default {
    name: 'DateTimePicker',
    components: { Input, Datepicker, Button },
    props: [
      'value',
      'label',
      'name',
      'items',
      'range',
      'disabled',
      'viewTime',
      'error'
    ],
    data: () => ({
      menu: true,
      valueHold: ''
    }),
    mounted() {
      this.resetTime()
    },
    watch: {
      value() {
        this.resetTime()
      }
    },
    computed: {
      formattedTime() {
        if (this.valueHold && this.valueHold.constructor == Array) {
          if (!this.valueHold) return [null, null]
          return (
            moment(this.valueHold[0]).format('DD.MM.YYYY HH:mm:ss') +
            ' - ' +
            (this.valueHold[1]
              ? moment(this.valueHold[1]).format('DD.MM.YYYY HH:mm:ss')
              : 'Active')
          )
        }
        if (!this.valueHold) return null
        return moment(this.valueHold).format('DD.MM.YYYY HH:mm:ss')
      }
    },
    methods: {
      resetTime() {
        if (
          this.value &&
          this.value.constructor &&
          this.value.constructor == Array
        ) {
          this.valueHold = [
            this.value[0] ? moment(this.value[0]).toDate() : null,
            this.value[1] ? moment(this.value[1]).toDate() : null
          ]
        } else {
          this.valueHold = this.value ? moment(this.value).toDate() : null
        }
      },
      check() {
        return document.activeElement.classList.contains('menu-wrap')
      },
      async blur() {
        setTimeout(() => {
          this.menu = this.check() ? true : false
        }, 50)
      },
      selectDateTime(val) {
        if (val && val.constructor === Array) {
          this.$emit(
            'input',
            val.map((v) => (v ? moment(v).format() : v))
          )
        } else {
          this.$emit('input', val ? moment(val).format() : val)
        }
      },
      focus() {
        this.$refs.input.focus()
      },
      input(val) {
        if (!val) this.selectDateTime(null)
      }
    }
  }
</script>

<style>
  .dp__menu,
  .dp__overlay,
  .dp__button,
  .dp__arrow_top {
    background-color: rgb(219, 219, 219) !important;
  }
  .dp__range_end,
  .dp__range_start,
  .dp__active_date,
  .dp__overlay_cell_active {
    background: #1a5989;
  }
  .dp__cell_inner {
    border: 0;
  }
  .dp__calendar_header_separator {
    background-color: transparent;
  }
  .dp__range_between {
    border-top: 1px solid #1a5989;
    border-bottom: 1px solid #1a5989;
  }
</style>
