<template>
  <div class="flex column height100">
    <div class="flex">
      <Button variant="smooth primary" class="mr-3" @click="today"
        >Today</Button
      >
      <Button variant="smooth primary" class="mr-3" @click="prev">
        <i class="icofont-simple-left mr-1"></i>
      </Button>
      <Button variant="smooth primary" @click="next">
        <i class="icofont-simple-right ml-1"></i>
      </Button>
      <div class="xs12 flex align-center justify-center">
        <h5>{{ dateRangeText }}</h5>
      </div>
      <Button
        @click="changeView('column', 'day')"
        :disabled="view == 'column' && selectedDateRange.range == 'day'"
        variant="smooth primary"
        class="mr-3"
      >
        Day
      </Button>
      <Button
        @click="changeView('column', 'isoWeek')"
        :disabled="view == 'column' && selectedDateRange.range == 'isoWeek'"
        variant="smooth primary"
      >
        Week
      </Button>
      <Button
        v-if="false"
        @click="changeView('grid', 'month')"
        :disabled="false"
        variant="smooth primary"
        class="ml-3"
      >
        Month
      </Button>
      <div></div>
    </div>
    <div v-if="view == 'grid'">
      <div
        v-for="w of weeks"
        :key="w.week"
        class="flex width100"
        style="height: 20%"
      >
        <div
          v-for="date of w.dates"
          :key="date"
          style="flex-basis: 16.666666667%; height: 100%"
          class="flex align-center justify-center glass-light-1"
        >
          <div>{{ date }}</div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="noselect flex xs12 ml-11"
      :class="selectedDateRange.range == 'day' ? 'mt-8' : ''"
      @mouseup="entryMouseup"
    >
      <div
        v-for="currentDate of week.dates"
        :key="currentDate.date"
        style="height: 100%; cursor: cell"
        class="flex align-center column position-relative xs12"
        :class="dragging ? 'cursor-grabbing' : ''"
      >
        <div class="py-3 noselect" v-if="selectedDateRange.range != 'day'">
          {{ currentDate.dateText }}
        </div>
        <div
          class="xs12 width100 position-relative"
          ref="column"
          @mousemove="mouseMove($event, currentDate.datetime)"
          @mousedown="createMousedown($event, currentDate.datetime)"
          :class="isToday(currentDate.datetime) ? 'primary-glass' : ''"
        >
          <div
            v-for="i in 24"
            :key="i"
            class="xs12 flex align-center position-relative"
            style="
              height: 4.1666666667%;
              outline: 1px solid rgba(0, 0, 0, 0.055);
            "
          >
            <div
              style="height: 1px; background-color: rgba(0, 0, 0, 0.04)"
              class="width100"
            ></div>
            <div
              v-if="currentDate.date == week.dates[0].date"
              class="flex justify-end align-end"
              style="position: absolute; left: -39px; top: -12px; width: 32px"
            >
              {{ ((i - 1 + '').length < 2 ? '0' : '') + (i - 1)
              }}<span
                style="font-size: 9px; bottom: 4px"
                class="position-relative"
                >:00</span
              >
            </div>
          </div>
          <div
            class="height100 position-absolute width100"
            style="top: 0; left: 0"
          >
            <div
              v-show="!dragEntry || dragEntry.id != entry.id"
              @mousedown.stop="
                entryMousedown($event, entry, currentDate.datetime)
              "
              v-tooltip="entry.calTitle"
              class="position-absolute entry noselect cursor-pointer"
              :class="create.dragging ? 'noPointerEvents' : ''"
              v-for="entry in currentDate.entries"
              :key="entry.id"
              :style="
                'height:' +
                entry.calHeight +
                ';left:' +
                entry.calLeft +
                ';top:' +
                entry.calTop +
                ';background-color:' +
                (projectColors[entry.projectId] ?? 'gray') +
                ';width:' +
                entry.calWidth +
                ';z-index:' +
                entry.calIndex
              "
            >
              <div
                style="
                  -webkit-line-clamp: 3;
                  line-clamp: 3;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: -webkit-box;
                  max-width: 100%;
                "
                class="mx-2"
              >
                <b class="nowrap mr-2">
                  {{ formatTime(entry.start) }}
                  {{ entry.end ? ' - ' + formatTime(entry.end) : '' }}</b
                >
                <span style="white-space: normal">{{ entry.calTitle }}</span>
              </div>
              <div
                class="dragEntryTop"
                @mousedown.stop="
                  entryMousedown($event, entry, currentDate.datetime, 'top')
                "
              ></div>
              <div
                class="dragEntryBottom"
                @mousedown.stop="
                  entryMousedown($event, entry, currentDate.datetime, 'bottom')
                "
              ></div>
            </div>
            <div
              v-tooltip="
                formatTime(deadline.deadline) +
                ' | ' +
                deadline.projectName +
                ' - ' +
                deadline.description
              "
              class="position-absolute entry noselect error"
              :class="create.dragging ? 'noPointerEvents' : ''"
              v-for="deadline in currentDate.deadlines"
              :key="deadline.taskId"
              :style="
                'height:18px' +
                ';left:0' +
                ';top:' +
                deadline.calTop +
                ';width:100%' +
                ';z-index:2500'
              "
            >
              <i class="icofont-hour-glass"></i>{{ deadline.description }}
            </div>
            <div
              v-if="hasDragEntry(currentDate.datetime) && dragEntry"
              class="position-absolute entry noselect"
              :style="
                'height:' +
                dragDates[currentDate.date].calHeight +
                ';left:' +
                0 +
                ';top:' +
                dragDates[currentDate.date].calTop +
                ';background-color:' +
                projectColors[dragEntry.projectId] +
                ';width:' +
                100 +
                '%;z-index:9999'
              "
            >
              <div
                style="
                  -webkit-line-clamp: 3;
                  line-clamp: 3;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: -webkit-box;
                  max-width: 100%;
                "
                class="mx-2"
              >
                <b class="nowrap mr-2">
                  {{ formatTime(dragEntry.start) }}
                  {{ dragEntry.end ? ' - ' + formatTime(dragEntry.end) : '' }}
                </b>
                <span style="white-space: normal">{{
                  dragEntry.calTitle
                }}</span>
              </div>
            </div>

            <div
              v-if="isToday(currentDate.datetime)"
              class="position-absolute noPointerEvents nowTimeLine"
              :style="'top:' + nowTimeLineTop"
            ></div>

            <div
              v-if="createDates[currentDate.date]"
              class="createNewDrag noPointerEvents flex column"
              :style="
                [
                  'top:' + createDates[currentDate.date].calTop,
                  'height:' + createDates[currentDate.date].calHeight
                ].join(';')
              "
            >
              <div
                @mousedown.stop
                class="flex justify-center py-2 align-start"
                v-if="
                  ((currentDate.datetime == create.dragColumn &&
                    dragResize == 'top') ||
                    (currentDate.datetime == dragStartDate &&
                      dragResize == 'bottom')) &&
                  create.moreThanAnHour
                "
              >
                <div class="flex justify-center align-center">
                  <i
                    v-if="dragResize == 'top'"
                    class="icofont-plus button-i primary-text"
                    :class="!create.dragging ? 'allPointerEvents' : ''"
                    v-tooltip="'Create new time entry'"
                    @click="startCreateTimeEntry()"
                  ></i>
                  <div
                    class="nowrap ml-3"
                    :class="dragResize == 'top' ? 'mr-4' : ''"
                  >
                    {{ formatTime(dialogs.createTimeEntry.form.start.default) }}
                  </div>
                </div>
              </div>

              <div
                @mousedown.stop
                class="flex justify-center height100 py-2 align-end"
                v-if="
                  ((currentDate.datetime == create.dragColumn &&
                    this.dragResize == 'bottom') ||
                    (currentDate.datetime == dragStartDate &&
                      dragResize == 'top')) &&
                  create.moreThanAnHour
                "
              >
                <div class="flex justify-center align-center">
                  <i
                    v-if="dragResize == 'bottom'"
                    class="icofont-plus button-i primary-text"
                    :class="!create.dragging ? 'allPointerEvents' : ''"
                    v-tooltip="'Create new time entry'"
                    @click="startCreateTimeEntry()"
                  ></i>
                  <div
                    class="nowrap ml-3"
                    :class="dragResize == 'bottom' ? 'mr-4' : ''"
                  >
                    {{ formatTime(dialogs.createTimeEntry.form.end.default) }}
                  </div>
                </div>
              </div>

              <div
                @mousedown.stop
                class="flex justify-center height100 py-2"
                :class="dragResize == 'top' ? 'align-start' : 'align-end'"
                v-else-if="
                  currentDate.datetime == create.dragColumn &&
                  !create.moreThanAnHour
                "
              >
                <div class="flex justify-center align-center">
                  <i
                    class="icofont-plus button-i primary-text"
                    :class="!create.dragging ? 'allPointerEvents' : ''"
                    v-tooltip="'Create new time entry'"
                    @click="startCreateTimeEntry()"
                  ></i>
                  <div class="nowrap ml-3">
                    {{ formatTime(dialogs.createTimeEntry.form.start.default) }}
                    -
                    {{ formatTime(dialogs.createTimeEntry.form.end.default) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      class="black-text"
      v-for="(dialog, key) of dialogs"
      :key="key"
      :value="dialog.dialog"
      @close="
        () => {
          dialog.dialog = false
          dialog.hasChanges = false
        }
      "
      :persistent="dialog.loading || (dialog.changeGuard && dialog.hasChanges)"
    >
      <DataForm
        :type="dialog.type"
        :value="dialog.form"
        :loading="dialog.loading"
        :disabled="dialog.loading"
        :allowCancel="true"
        @cancel="dialog.dialog = false"
        @update="dialogOk(key, $event)"
        :title="dialog.title"
        @updateHasChanges="dialog.hasChanges = $event"
      ></DataForm>
    </Dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  moment.fn.roundNext5Min = function () {
    var intervals = Math.floor(this.minutes() / 5)
    if (this.minutes() % 5 != 0) intervals++
    if (intervals == 12) {
      this.add('hours', 1)
      intervals = 0
    }
    this.minutes(intervals * 5)
    this.seconds(0)
    return this
  }

  import {
    durationAsSeconds,
    getFirstLineFromHtml,
    durationAsHours
  } from '../../utils'
  import Dialog from '../common/Dialog.vue'
  import DataForm from '../common/DataForm.vue'
  import Button from '../common/Button.vue'

  export default {
    name: 'Calendar',
    components: { Dialog, DataForm, Button },
    props: [],
    data: () => ({
      view: 'column',
      selectedDateRange: {
        start: moment().startOf('isoWeek').format(),
        end: moment().endOf('isoWeek').format(),
        range: 'isoWeek'
      },
      dragStart: 0,
      dragEntry: null,
      dragEntryStartedDrag: false,
      dragging: false,
      dragStartDate: null,
      dragEndDate: null,
      dragNewStartDate: null,
      dragNewEndDate: null,
      dragDates: {},
      dragDateColumn: null,
      dragResize: null,
      create: {
        dragStart: 0,
        start: null,
        end: null,
        dragging: false,
        dragColumn: null,
        moreThanAnHour: false
      },
      createDates: {},
      resizeObserver: null,
      columnHeight: 0,
      dialogs: {
        createTimeEntry: {
          dialog: false,
          loading: false,
          title: 'Create entry',
          type: 'create',
          changeGuard: false,
          hasChanges: false,
          selectedEntryId: null,
          form: {
            start: {
              type: 'datetime',
              label: 'Start',
              default: null,
              viewTime: true,
              validate: (val) => !!val || 'Must have start time'
            },
            end: {
              type: 'datetime',
              label: 'End',
              default: null,
              viewTime: true
            },
            projectId: {
              type: 'select',
              label: 'Project',
              default: '',
              options: [],
              viewTime: true
            },
            description: {
              type: 'textarea',
              label: 'Description',
              default: ''
            },
            deleteButton: {
              type: 'button',
              label: 'Delete <i class="icofont-ui-delete"></i>',
              click: null,
              visible: true
            }
          }
        },
        deleteTimeEntry: {
          dialog: false,
          loading: false,
          title: 'Delete entry',
          type: 'yesno'
        }
      },
      todayDate: moment().format(),
      todayDateTimer: null
    }),
    async mounted() {
      await this.$nextTick()
      this.resizeObserver = new ResizeObserver(this.onResize)
      this.resizeObserver.observe(this.$refs.column[0])
      this.updateSelectOptions()
      this.dialogs.createTimeEntry.form.deleteButton.click =
        this.startDeleteTimeEntry
      this.todayDateTimerTick()
    },
    beforeUnmount() {
      this.resizeObserver.unobserve(this.$refs.column[0])
    },
    watch: {
      projects() {
        this.updateSelectOptions()
      }
    },
    computed: {
      projectColors() {
        const colors = {}
        for (const project of this.projects) {
          colors[project.id] = project.color
        }
        return colors
      },
      timeEntries() {
        return this.$store.getters.timeEntries
      },
      tasks() {
        return this.$store.getters.tasks
      },
      uncompletedTasksWithDeadline() {
        return this.tasks.filter((task) => !task.completedAt && task.deadline)
      },
      projects() {
        return this.$store.getters.projects
      },
      week() {
        return this.weeks.find(
          (week) => week.week == moment(this.selectedDateRange.start).week()
        )
      },
      weeks() {
        const weeks = []
        let currentDate = moment(this.selectedDateRange.start)
        const stopDate = moment(this.selectedDateRange.end)
        while (currentDate.isSameOrBefore(stopDate)) {
          const week = {
            week: currentDate.week(),
            dates: []
          }

          let dayCurrentDate = moment(this.selectedDateRange.start)
          const dayLastDate = moment(this.selectedDateRange.end).endOf('day')

          while (dayCurrentDate.isBefore(dayLastDate)) {
            let newDateEntry = {
              date: dayCurrentDate.date(),
              dateText: dayCurrentDate.format('ddd D'),
              datetime: dayCurrentDate.startOf('day').format(),
              entries: this.timeEntries
                .filter(
                  (entry) =>
                    parseInt(dayCurrentDate.format('YYYYMMDD')) >=
                      parseInt(moment(entry.start).format('YYYYMMDD')) &&
                    parseInt(dayCurrentDate.format('YYYYMMDD')) <=
                      parseInt(
                        moment(entry.end || undefined).format('YYYYMMDD')
                      )
                )
                .map((entry) => {
                  let start = entry.start
                  let end = entry.end || moment().format()

                  if (moment(start).date() != dayCurrentDate.date()) {
                    start = dayCurrentDate.startOf('day').format()
                  }
                  if (moment(end).date() != dayCurrentDate.date()) {
                    end = dayCurrentDate.endOf('day').format()
                  }

                  entry.calHeight =
                    durationAsSeconds(start, end) * 0.001157407407 + '%'
                  entry.calTop =
                    durationAsSeconds(moment(start).startOf('day'), start) *
                      0.001157407407 +
                    '%'

                  entry.calTitle = getFirstLineFromHtml(entry.description)
                  entry.calWidth = '100%'
                  return { ...entry }
                })
                .sort((a, b) => {
                  return moment(a).isAfter(moment(b)) ? 1 : 0
                }),
              deadlines: []
            }
            for (let i = 0; i < newDateEntry.entries.length; i++) {
              const entry = newDateEntry.entries[i]
              const overlaps = this.getOverlaps(entry, newDateEntry.entries)

              if (overlaps.length) {
                let xindex = 0
                for (const e of overlaps) {
                  if (e.xindex == xindex) xindex++
                }
                entry.calWidth = '100%'
                entry.calIndex = i
                entry.xindex = xindex
              } else {
                entry.xindex = 0
              }
              entry.calLeft = '0'
            }

            for (let i = 0; i < newDateEntry.entries.length; i++) {
              const entry = newDateEntry.entries[i]
              const overlaps = this.getOverlaps(entry, newDateEntry.entries)
              if (overlaps.length) {
                let maxXindex = entry.xindex
                for (const e of overlaps) {
                  if (e.xindex > maxXindex) maxXindex = e.xindex
                }
                maxXindex++
                entry.maxXindex = maxXindex
              }
            }

            for (let i = 0; i < newDateEntry.entries.length; i++) {
              const entry = newDateEntry.entries[i]
              const overlaps = this.getOverlaps(entry, newDateEntry.entries)
              if (overlaps.length) {
                let maxXindex = entry.maxXindex
                for (const e of overlaps) {
                  if (e.maxXindex > maxXindex) maxXindex = e.maxXindex
                }
                entry.maxXindex = maxXindex
                entry.calWidth = 100 / maxXindex + '%'
                entry.calLeft = (100 / maxXindex) * entry.xindex + '%'
              }
            }

            week.dates.push(newDateEntry)

            for (const task of this.tasks) {
              if (
                task.deadline &&
                moment(task.deadline).isSame(dayCurrentDate, 'day')
              ) {
                newDateEntry.deadlines.push({
                  projectName: this.getProjectTitle(task.projectId),
                  taskId: task.id,
                  deadline: task.deadline,
                  description: getFirstLineFromHtml(task.description),
                  calTop:
                    durationAsSeconds(
                      moment(task.deadline).startOf('day'),
                      task.deadline
                    ) *
                      0.001157407407 +
                    '%'
                })
              }
            }

            dayCurrentDate.add(1, 'days')
          }

          weeks.push(week)
          currentDate.add(7, 'days')
        }

        return weeks
      },
      dateRangeText() {
        const start = moment(this.selectedDateRange.start)
        const end = moment(this.selectedDateRange.end)
        if (this.selectedDateRange.range == 'isoWeek') {
          if (start.month() != end.month()) {
            return (
              'Week ' +
              start.isoWeek() +
              ', ' +
              start.format('MMMM') +
              ' - ' +
              end.format('MMMM YYYY')
            )
          }
          return 'Week ' + start.isoWeek() + ', ' + start.format('MMMM YYYY')
        }
        if (this.selectedDateRange.range == 'day') {
          return start.format('dddd, D. MMMM YYYY')
        }
        return ''
      },
      nowTimeLineTop() {
        return (
          durationAsSeconds(
            moment(this.todayDate).startOf('day'),
            moment(this.todayDate)
          ) *
            0.001157407407 +
          '%'
        )
      }
    },
    methods: {
      todayDateTimerTick() {
        clearTimeout(this.todayDateTimer)
        this.todayDateTimer = setTimeout(() => {
          this.todayDate = moment().format()
          this.todayDateTimerTick()
        }, 60000)
      },
      next() {
        this.selectedDateRange.start = moment(this.selectedDateRange.start).add(
          1,
          this.selectedDateRange.range
        )
        this.selectedDateRange.end = moment(this.selectedDateRange.end).add(
          1,
          this.selectedDateRange.range
        )
      },
      prev() {
        this.selectedDateRange.start = moment(this.selectedDateRange.start).add(
          -1,
          this.selectedDateRange.range
        )
        this.selectedDateRange.end = moment(this.selectedDateRange.end).add(
          -1,
          this.selectedDateRange.range
        )
      },
      today() {
        this.selectedDateRange.start = moment().startOf(
          this.selectedDateRange.range
        )
        this.selectedDateRange.end = moment().endOf(
          this.selectedDateRange.range
        )
      },
      changeView(view, range) {
        this.view = view
        this.selectedDateRange.range = range

        this.selectedDateRange.start = moment(
          this.selectedDateRange.start
        ).startOf(range)
        this.selectedDateRange.end = moment(this.selectedDateRange.start).endOf(
          range
        )
      },
      updateSelectOptions() {
        this.dialogs.createTimeEntry.form.projectId.options = this.projects.map(
          (p) => ({
            value: p.id,
            text: p.title
          })
        )
      },
      hasDragEntry(date) {
        return (
          parseInt(moment(date).format('YYYYMMDD')) >=
            parseInt(moment(this.dragNewStartDate).format('YYYYMMDD')) &&
          parseInt(moment(date).format('YYYYMMDD')) <=
            parseInt(moment(this.dragNewEndDate).format('YYYYMMDD'))
        )
      },
      getDate(val) {
        return moment(val).date()
      },
      getOverlaps(entry, entries) {
        return entries.filter(
          (e) =>
            (moment(entry.start).isBefore(moment(e.start)) &&
              moment(e.start).isBefore(moment(entry.end || undefined))) ||
            (moment(entry.start).isBefore(moment(e.end)) &&
              moment(e.end).isBefore(moment(entry.end || undefined))) ||
            (moment(e.start).isBefore(moment(entry.start)) &&
              moment(entry.end || undefined).isBefore(moment(e.end)))
        )
      },
      formatTime(val) {
        return moment(val).format('HH:mm')
      },
      async entryMouseup(e) {
        if (this.create.dragging) return this.createMouseup(e)
        if (!this.dragging) return
        const payload = { ...this.dragEntry }
        this.dragging = false

        const entry = this.timeEntries.find((e) => e.id == payload.id)

        if (!this.dragEntryStartedDrag) {
          this.dialogs.createTimeEntry.selectedEntryId = entry.id
          this.dialogs.createTimeEntry.form.start.default = entry.start
          this.dialogs.createTimeEntry.form.end.default = entry.end
          this.dialogs.createTimeEntry.form.projectId.default = entry.projectId
          this.dialogs.createTimeEntry.form.description.default =
            entry.description
          this.dialogs.createTimeEntry.form.deleteButton.visible = true
          this.dialogs.createTimeEntry.dialog = true
          this.dialogs.createTimeEntry.title = 'Edit entry'
          this.dialogs.createTimeEntry.type = 'edit'
          this.dragEntry = null
          return
        }

        await this.$store.dispatch('updateTimeEntry', {
          id: payload.id,
          start: payload.start,
          end: payload.end
        })
        this.dragEntry = null
      },
      entryMousedown(e, entry, columnDate, resize) {
        this.dragEntryStartedDrag = false
        this.dragStart = e.screenY
        this.dragEntry = entry
        this.dragging = true
        this.dragStartDate = entry.start
        this.dragEndDate = entry.end || moment().format()
        this.dragNewStartDate = entry.start
        this.dragNewEndDate = entry.end || moment().format()
        this.dragDateColumn = columnDate
        this.dragResize = resize
        this.createDates = {}

        this.updateDragDates(e, columnDate)
      },
      updateDragDates(e, date) {
        if (
          moment(this.dragDateColumn).format('YYYYMMDD') !=
          moment(date).format('YYYYMMDD')
        ) {
          const diffDays = -moment(this.dragDateColumn)
            .startOf('day')
            .diff(moment(date).startOf('day'), 'days')
          if (!this.dragResize || this.dragResize == 'top') {
            this.dragStartDate = moment(this.dragStartDate)
              .add(diffDays, 'days')
              .format()
          }
          if (!this.dragResize || this.dragResize == 'bottom') {
            this.dragEndDate = moment(this.dragEndDate)
              .add(diffDays, 'days')
              .format()
          }
          this.dragEntryStartedDrag = true
        }
        this.dragDateColumn = date

        const difference = e.screenY - this.dragStart
        const differenceSeconds = parseInt(
          ((24 * 60 * 60) / this.columnHeight) * difference
        )

        if (differenceSeconds > 1 || differenceSeconds < -1) {
          this.dragEntryStartedDrag = true
        }

        let start = moment(this.dragStartDate).add(differenceSeconds, 'seconds')
        let end = moment(this.dragEndDate).add(differenceSeconds, 'seconds')

        if (this.dragEntryStartedDrag) {
          start = start.roundNext5Min()
          end = end.roundNext5Min()
        }

        if (this.dragResize) {
          if (this.dragResize == 'top') {
            end = moment(this.dragEndDate)
          } else if (this.dragResize == 'bottom') {
            start = moment(this.dragStartDate)
          }
        }

        const dates = {}
        let currentDate = start.clone()
        let stopDate = end.clone().endOf('day')

        while (currentDate.isBefore(stopDate)) {
          const currentDateNumber = currentDate.date()

          dates[currentDateNumber] = {
            start: start.clone(),
            end: end.clone()
          }
          if (dates[currentDateNumber].start.date() != currentDateNumber) {
            dates[currentDateNumber].start = currentDate.clone().startOf('day')
          }
          if (dates[currentDateNumber].end.date() != currentDateNumber) {
            dates[currentDateNumber].end = currentDate.clone().endOf('day')
          }

          dates[currentDateNumber].calTop =
            durationAsSeconds(
              moment(dates[currentDateNumber].start).startOf('day'),
              dates[currentDateNumber].start
            ) *
              0.001157407407 +
            '%'

          dates[currentDateNumber].calHeight =
            durationAsSeconds(
              dates[currentDateNumber].start,
              dates[currentDateNumber].end
            ) *
              0.001157407407 +
            '%'

          currentDate.add(1, 'days')
        }

        if (!this.dragResize || this.dragResize == 'top') {
          this.dragEntry.start = start
          this.dragNewStartDate = start
        }
        if (!this.dragResize || this.dragResize == 'bottom') {
          this.dragNewEndDate = end
          this.dragEntry.end = end
        }

        this.dragDates = dates
      },
      mouseMove(e, date) {
        if (this.create.dragging) return this.createMousemove(e, date)
        if (!this.dragging) return

        this.updateDragDates(e, date)
      },
      createMousemove(e, date) {
        let diffDays = 0
        if (!moment(this.create.dragColumn).isSame(date, 'day')) {
          diffDays = -moment(this.create.dragColumn)
            .startOf('day')
            .diff(moment(date).startOf('day'), 'days')
        }

        if (
          this.dragResize == 'bottom' &&
          moment(this.create.start).isAfter(
            moment(date).add(
              parseInt((e.offsetY / this.columnHeight) * 24 * 60 * 60),
              'seconds'
            )
          )
        ) {
          const holdStart = this.create.start + ''
          this.create.start = this.create.end
          this.create.end = holdStart
          this.dragResize = 'top'
        } else if (
          this.dragResize == 'top' &&
          moment(this.create.end).isBefore(
            moment(date).add(
              parseInt((e.offsetY / this.columnHeight) * 24 * 60 * 60),
              'seconds'
            )
          )
        ) {
          const holdStart = this.create.start + ''
          this.create.start = this.create.end
          this.create.end = holdStart
          this.dragResize = 'bottom'
        }

        if (!this.dragResize || this.dragResize == 'top') {
          this.create.start = moment(this.create.start)
            .add(diffDays, 'days')
            .format()
        }

        if (!this.dragResize || this.dragResize == 'bottom') {
          this.create.end = moment(this.create.end)
            .add(diffDays, 'days')
            .format()
        }
        this.create.dragColumn = date
        this.updateCreateDates(e)
      },
      createMouseup() {
        this.create.dragging = false
      },
      createMousedown(e, date) {
        this.create.dragging = true
        this.create.dragColumn = date
        this.dragStartDate = date
        this.dragResize = 'bottom'
        this.create.dragStart = e.offsetY

        const pointInTime = moment(date)
          .add(
            parseInt(((24 * 60 * 60) / this.columnHeight) * e.offsetY),
            'seconds'
          )
          .roundNext5Min()

        this.create.start = pointInTime.format()
        this.create.end = pointInTime.format()

        this.updateCreateDates(e)
      },
      updateCreateDates(e) {
        const dates = {}
        let currentDate = moment(this.create.start).startOf('day')
        let lastDate = moment(this.create.end).endOf('day')

        const difference = e.offsetY - this.create.dragStart
        const differenceInSeconds = parseInt(
          ((24 * 60 * 60) / this.columnHeight) * difference
        )

        let start = moment(this.create.start)
          .add(differenceInSeconds, 'seconds')
          .roundNext5Min()
        let end = moment(this.create.end)
          .add(differenceInSeconds, 'seconds')
          .roundNext5Min()

        if (this.dragResize) {
          if (this.dragResize == 'top') {
            end = moment(this.create.end)
          } else if (this.dragResize == 'bottom') {
            start = moment(this.create.start)
          }
        }
        this.dialogs.createTimeEntry.form.start.default = start.format()
        this.dialogs.createTimeEntry.form.end.default = end.format()

        if (durationAsHours(start, end) > 1) {
          this.create.moreThanAnHour = true
        } else this.create.moreThanAnHour = false

        while (currentDate.isBefore(lastDate)) {
          const currentDateNumber = currentDate.date()

          dates[currentDateNumber] = {
            start: start.clone(),
            end: end.clone()
          }

          if (dates[currentDateNumber].start.date() != currentDateNumber) {
            dates[currentDateNumber].start = currentDate.clone().startOf('day')
          }
          if (dates[currentDateNumber].end.date() != currentDateNumber) {
            dates[currentDateNumber].end = currentDate.clone().endOf('day')
          }

          const calTopSeconds = durationAsSeconds(
            moment(dates[currentDateNumber].start).startOf('day'),
            dates[currentDateNumber].start
          )

          dates[currentDateNumber].calTop = calTopSeconds * 0.001157407407 + '%'

          dates[currentDateNumber].calHeight =
            durationAsSeconds(
              dates[currentDateNumber].start,
              dates[currentDateNumber].end
            ) * 0.001157407407

          dates[currentDateNumber].calHeightNumber =
            dates[currentDateNumber].calHeight

          dates[currentDateNumber].calHeight += '%'

          currentDate.add(1, 'days')
        }

        const dateKeys = Object.keys(dates)
        // console.log('dates', dates)

        if (
          dateKeys.length == 1 &&
          dates[dateKeys[0]].calHeightNumber <
            (this.columnHeight / 24 / 60 / 60) * 5
        ) {
          this.createDates = {}
        } else {
          this.createDates = dates
        }
      },
      onResize() {
        this.columnHeight =
          (this.$refs.column[0] && this.$refs.column[0].clientHeight) || 0
      },
      startCreateTimeEntry() {
        this.dialogs.createTimeEntry.form.deleteButton.visible = false
        this.dialogs.createTimeEntry.form.projectId.default = null
        this.dialogs.createTimeEntry.form.description.default = ''
        this.dialogs.createTimeEntry.title = 'Create entry'
        this.dialogs.createTimeEntry.type = 'create'
        this.dialogs.createTimeEntry.dialog = true
      },
      async dialogOk(key, payload) {
        this.dialogs[key].loading = true
        if (key == 'createTimeEntry' && this.dialogs[key].type == 'create') {
          await this.$store.dispatch('createTimeEntry', {
            ...payload
          })
        } else if (
          key == 'createTimeEntry' &&
          this.dialogs[key].type == 'edit'
        ) {
          await this.$store.dispatch('updateTimeEntry', {
            id: this.dialogs[key].selectedEntryId,
            ...payload
          })
        } else if (key == 'deleteTimeEntry') {
          await this.$store.dispatch(
            'deleteTimeEntry',
            this.dialogs.createTimeEntry.selectedEntryId
          )
          this.dialogs.createTimeEntry.dialog = false
        }

        this.createDates = {}

        this.dialogs[key].hasChanges = false
        this.dialogs[key].loading = false
        this.dialogs[key].dialog = false
      },
      startDeleteTimeEntry() {
        this.dialogs.deleteTimeEntry.dialog = true
      },
      isToday(date) {
        return moment().isSame(date, 'day')
      },
      isDayAfter(start, end) {
        return moment(start).startOf('day').isAfter(moment(end).startOf('day'))
      },
      isDayBefore(start, end) {
        return moment(start).startOf('day').isBefore(moment(end).startOf('day'))
      },
      getProjectTitle(projectId) {
        const project = this.projects.find((p) => p.id == projectId)
        return project.title
      }
    }
  }
</script>

<style scoped>
  .entry {
    font-family: 'Helvetica';
    font-size: 14px;
    outline: 1px solid rgba(0, 0, 0, 0.55);
    overflow: hidden;
    /* border-radius: 2px; */
  }
  .dragEntryTop {
    cursor: n-resize;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
  }
  .dragEntryBottom {
    cursor: s-resize;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
  }
  .nowTimeLine {
    width: 100%;
    z-index: 9998;
    box-shadow: rgb(27 115 203 / 40%) 0px 0px 2px 1px;
    background-color: rgb(27 115 203 / 40%);
    height: 1px;
    left: 0;
  }
  .createNewDrag {
    position: absolute;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
    font-size: 12px;
    z-index: 5000;
  }
</style>
