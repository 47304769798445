<template>
  <Dialog
    :value="dialog"
    @close="
      () => {
        dialog = false
        hasChanges = false
      }
    "
    :persistent="loading || (changeGuard && hasChanges)"
  >
    <DataForm
      :type="type"
      :value="form"
      :loading="loading"
      :disabled="loading"
      :allowCancel="true"
      @cancel="dialog = false"
      @update="dialogOk($event)"
      :title="title"
      @updateHasChanges="hasChanges = $event"
    ></DataForm>
  </Dialog>
</template>

<script>
  import DataForm from '../common/DataForm.vue'
  import Dialog from '../common/Dialog.vue'

  export default {
    name: 'CreateBoardDialog',
    components: { Dialog, DataForm },
    props: ['projectId'],
    data: () => ({
      dialog: false,
      loading: false,
      title: 'Create board',
      type: 'create',
      changeGuard: false,
      form: {
        title: {
          type: 'text',
          label: 'Title',
          default: '',
          validate: (val) =>
            (val && val.length > 0) || 'Must be atleast 1 character long'
        }
      }
    }),
    methods: {
      showDialog() {
        this.dialog = true
      },
      async dialogOk(payload) {
        this.loading = true
        await this.$store.dispatch('createBoard', {
          projectId: this.projectId,
          ...payload
        })
        this.hasChanges = false
        this.dialog = false
        this.loading = false
      }
    }
  }
</script>

<style scoped></style>
