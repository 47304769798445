<template>
  <div class="xs12 py-3 flex align-center entry">
    <div class="xs4">
      <DateTimePicker
        :range="true"
        :value="[value.start, value.end]"
        class="mr-2"
        :viewTime="true"
        @input="updateEntryStartEnd($event)"
      ></DateTimePicker>
    </div>
    <div class="xs1">
      <div class="ml-2 button-i disabled text-ellipsis">
        {{ countHours(value.start, value.end) }}
      </div>
    </div>
    <div class="xs3">
      <div
        v-tooltip="'Edit entry description'"
        class="ml-2 mt-1 button-i text-ellipsis"
        :style="!value.description ? 'font-style: italic' : ''"
        @click="startEditTimeEntryDescription(value)"
        v-html="
          getFirstLineFromHtml(value.description)
            ? getFirstLineFromHtml(value.description)
            : `<span style='font-style:italic'>No description</span>`
        "
      ></div>
    </div>
    <div class="xs2">
      <Select
        class="mx-2"
        :value="value.projectId"
        :items="projectItems"
        @input="updateEntry('projectId', $event)"
      ></Select>
    </div>
    <div class="xs2 flex">
      <i
        v-tooltip="'Duplicate entry'"
        class="icofont-ui-copy button-i ml-3"
        @click="duplicateEntry(value)"
        v-if="!loadingDuplicate"
      ></i>
      <Loading v-else class="ml-3" size="16"></Loading>
      <i
        v-tooltip="'Delete entry'"
        class="icofont-ui-delete button-i ml-3"
        @click="dialogs.confirmDeleteTimeEntry.dialog = true"
      ></i>
      <i
        v-tooltip="'More settings for entry'"
        class="icofont-options button-i ml-3"
      ></i>
    </div>
    <Dialog
      class="black-text"
      v-for="(dialog, key) of dialogs"
      :key="key"
      :value="dialog.dialog"
      @close="
        () => {
          dialog.dialog = false
          dialog.hasChanges = false
        }
      "
      :persistent="dialog.loading || (dialog.changeGuard && dialog.hasChanges)"
    >
      <DataForm
        :type="dialog.type"
        :value="dialog.form"
        :loading="dialog.loading"
        :disabled="dialog.loading"
        :allowCancel="true"
        @cancel="dialog.dialog = false"
        @update="dialogOk(key, $event)"
        :title="dialog.title"
        @updateHasChanges="dialog.hasChanges = $event"
      ></DataForm>
    </Dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  import Select from '../common/Select.vue'
  import DateTimePicker from '../common/DateTimePicker.vue'
  import Dialog from '../common/Dialog.vue'
  import DataForm from '../common/DataForm.vue'
  import Loading from '../common/Loading.vue'
  import { getFirstLineFromHtml, durationFormat } from '../../utils'

  export default {
    name: 'ReportEntry',
    components: {
      DateTimePicker,
      Select,
      Dialog,
      DataForm,
      Loading
    },
    props: ['value'],
    data: () => ({
      loadingDuplicate: false,
      dialogs: {
        confirmDeleteTimeEntry: {
          dialog: false,
          loading: false,
          title: 'Are you sure you want to delete the time entry?',
          type: 'yesno',
          form: {}
        },
        timeEntryDescriptionEditor: {
          dialog: false,
          loading: false,
          title: 'Edit entry description',
          type: 'edit',
          selectedEntry: null,
          changeGuard: true,
          hasChanges: false,
          form: {
            description: {
              type: 'textarea',
              label: 'Description',
              default: ''
            }
          }
        }
      }
    }),
    computed: {
      projects() {
        return this.$store.getters.projects
      },
      projectItems() {
        return this.projects.map((p) => ({ value: p.id, text: p.title }))
      }
    },
    methods: {
      countHours(start, end) {
        const duration = moment.duration(
          moment(end || undefined).diff(moment(start))
        )
        return (
          durationFormat(start, end || undefined) +
          ' (' +
          parseFloat(Math.round(duration.asHours() * 100) / 100) +
          ')'
        )
      },
      getFirstLineFromHtml,
      formatDateTime(datetime) {
        return moment(datetime).format('DD.MM.YYYY HH:mm:ss')
      },
      async dialogOk(key, payload) {
        this.dialogs[key].loading = true
        if (key == 'timeEntryDescriptionEditor') {
          const timeEntry =
            this.dialogs.timeEntryDescriptionEditor.selectedEntry
          await this.$store.dispatch('updateTimeEntry', {
            id: timeEntry.id,
            ...payload
          })
        } else if (key == 'confirmDeleteTimeEntry') {
          await this.$store.dispatch('deleteTimeEntry', this.value.id)
        }
        this.dialogs[key].hasChanges = false
        this.dialogs[key].loading = false
        this.dialogs[key].dialog = false
      },
      startEditTimeEntryDescription(timeEntry) {
        const key = 'timeEntryDescriptionEditor'
        this.dialogs[key].selectedEntry = timeEntry
        this.dialogs[key].form.description.default = timeEntry.description
        this.dialogs[key].hasChanges = false
        this.dialogs[key].dialog = true
      },
      async duplicateEntry(entry) {
        this.loadingDuplicate = true
        entry = JSON.parse(JSON.stringify(entry))
        delete entry.id
        await this.$store.dispatch('createTimeEntry', entry)
        this.loadingDuplicate = false
      },
      async updateEntry(prop, value) {
        const payload = { id: this.value.id }
        payload[prop] = value
        await this.$store.dispatch('updateTimeEntry', payload)
      },
      async updateEntryStartEnd(startEnd) {
        const payload = {
          id: this.value.id,
          start: startEnd[0],
          end: startEnd[1]
        }
        await this.$store.dispatch('updateTimeEntry', payload)
      }
    }
  }
</script>

<style scoped>
  .entry:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
</style>
