<template>
  <div class="flex height100">
    <div>
      <div class="flex align-center mb-8">
        <Select
          label="Project"
          :items="projectItems"
          :value="selectedProjectId"
          @input="selectedProjectId = $event"
          :disabled="createNewMode"
        ></Select>
        <div
          :class="createNewMode ? 'disabled' : ''"
          v-tooltip="'Create new project'"
          class="mt-5 ml-4 button-i"
          @click="enableCreateNewMode"
        >
          <i class="icofont-plus"></i>
        </div>
      </div>
      <div>
        <Input
          label="Title"
          ref="titleInput"
          :value="titleInput"
          @input="titleInput = $event"
          :disabled="!selectedProjectId && !createNewMode"
          class="mb-4"
        ></Input>
        <Input
          label="Color"
          ref="colorInput"
          :value="colorInput"
          @input="colorInput = $event"
          :disabled="!selectedProjectId && !createNewMode"
          class="mb-4"
        ></Input>
      </div>
      <div class="mt-4 flex">
        <Button
          :loading="saveLoading"
          :disabled="saveLoading || (!selectedProjectId && !createNewMode)"
          @click="cancelEdit"
          variant="text"
          >Cancel
        </Button>
        <div class="xs12"></div>
        <Button
          :loading="saveLoading"
          :disabled="saveLoading || (!selectedProjectId && !createNewMode)"
          @click="saveProject"
          variant="primary smooth"
          >{{ createNewMode ? 'Create' : 'Save' }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
  import Input from '../common/Input.vue'
  import Button from '../common/Button.vue'
  import Select from '../common/Select.vue'

  export default {
    name: 'Projects',
    components: { Input, Button, Select },
    data: () => ({
      selectedProjectId: null,
      titleInput: '',
      colorInput: '',
      saveLoading: false,
      createNewMode: false
    }),
    props: [],
    computed: {
      selectedProject() {
        return this.projectsOrdered.find((p) => p.id == this.selectedProjectId)
      },
      projectItems() {
        return this.projectsOrdered.map((p) => ({ value: p.id, text: p.title }))
      },
      projectsOrdered() {
        return this.$store.getters.projectsOrdered
      }
    },
    watch: {
      selectedProjectId() {
        if (this.selectedProjectId) {
          this.titleInput = this.selectedProject.title
          this.colorInput = this.selectedProject.color
        } else {
          this.titleInput = ''
          this.colorInput = ''
        }
      }
    },
    methods: {
      selectProject(project) {
        this.selectedProjectId = project.id
        this.titleInput = project.title
      },
      enableCreateNewMode() {
        this.selectedProjectId = null
        this.createNewMode = true
        this.$nextTick(() => {
          this.$refs.titleInput.focus()
        })
      },
      cancelEdit() {
        if (this.selectedProjectId) {
          if (
            this.titleInput == this.selectedProject.title &&
            this.colorInput == this.selectedProject.color
          ) {
            this.selectedProjectId = null
            this.titleInput = ''
            this.colorInput = ''
          } else {
            this.titleInput = this.selectedProject.title
            this.colorInput = this.selectedProject.color
          }
        } else {
          if (this.titleInput || this.colorInput) {
            this.titleInput = ''
            this.colorInput = ''
          } else {
            this.titleInput = ''
            this.colorInput = ''
            this.createNewMode = false
          }
        }
      },
      async saveProject() {
        this.saveLoading = true
        let action = 'updateItems'
        if (this.createNewMode) {
          action = 'createItems'
        }
        await this.$store.dispatch(action, {
          projects: [
            {
              id: this.selectedProjectId,
              title: this.titleInput,
              color: this.colorInput
            }
          ]
        })
        if (this.createNewMode) {
          this.cancelEdit()
          this.cancelEdit()
        }
        this.saveLoading = false
      }
    }
  }
</script>

<style scoped></style>
