<template>
  <div class="py-2">
    <div class="flex pa-3 task wrap">
      <div class="flex xs12">
        <div class="xs12 text-ellipsis pb-2">
          {{ getFirstLineFromHtml(value.description) }}
        </div>
        <div v-if="otherUrls.length">
          <i
            class="ml-3 button-i icofont-link"
            v-tooltip="'Open link in description'"
            @mousedown.stop
            @click="openUrl(otherUrls[0])"
          ></i>
        </div>
        <div v-if="!value.completedAt">
          <template v-if="!loading.startStopButton">
            <i
              v-if="!isActive()"
              class="ml-3 button-i icofont-ui-play"
              v-tooltip="'Start timer'"
              @mousedown.stop
              @click="createEntry()"
            ></i>
            <i
              v-else
              class="ml-3 button-i icofont-square"
              v-tooltip="'Stop timer'"
              @mousedown.stop
              @click="stopEntry"
            ></i>
          </template>
          <LoadingDark v-else size="16" class="ml-3 mt-1"></LoadingDark>
        </div>
      </div>
      <div class="flex xs12">
        <div
          v-if="value.deadline"
          class="nowrap deadline"
          :class="deadlineColor"
        >
          {{ formatDate(value.deadline) }}
        </div>
        <div class="xs12"></div>
        <div
          class="pr-3 nowrap"
          v-tooltip="
            timeSpentPlannedRatio
              ? 'Used ' + timeSpentPlannedRatio * 100 + '% of planned time'
              : ''
          "
        >
          <span
            v-if="totalTime"
            class="font-weight-bold"
            :class="timeSpentColor + '-text'"
          >
            {{ totalTime }}
          </span>
          <span
            v-if="totalTime && value.plannedDurationHours"
            class="mx-2"
            style="color: rgba(0, 0, 0, 0.35) !important"
          >
            {{ totalTime && value.plannedDurationHours ? '/' : '' }}
          </span>
          <span
            v-if="value.plannedDurationHours"
            style="color: rgba(0, 0, 0, 0.35) !important"
          >
            {{ value.plannedDurationHours }}
          </span>
        </div>
        <div class="nowrap" v-if="checkboxStatus">
          <i class="icofont-checked mr-2"></i>{{ checkboxStatus }}
        </div>
        <div v-if="githubUrl">
          <img
            height="20"
            class="cursor-pointer ml-3"
            v-tooltip="'Open github link in description'"
            src="../../assets/github-black.png"
            @mousedown.stop
            @click="openUrl(githubUrl)"
          />
        </div>
        <div @click="$emit('taskEditor')">
          <i
            class="ml-3 button-i icofont-pencil-alt-2"
            v-tooltip="'Edit task'"
            @mousedown.stop
          ></i>
        </div>
        <div>
          <i
            v-if="value.completedAt"
            class="ml-3 icofont-archive"
            :class="
              value.archivedAt ? 'primary-text cursor-default' : 'button-i'
            "
            v-tooltip="value.archivedAt ? 'Task is archived' : 'Archive task'"
            @mousedown.stop
            @click="!value.archivedAt ? $emit('confirmArchiveDialog') : false"
          ></i>
        </div>
        <div>
          <i
            v-if="!uncheckedCheckboxes"
            class="ml-3 button-i icofont-ui-check"
            :class="value.completedAt ? 'success-text' : ''"
            @click="
              !value.completedAt
                ? $emit('confirmCompletionDialog')
                : $emit('resetCompletionDialog')
            "
            v-tooltip="
              isActive()
                ? 'Stop timer and mark as completed'
                : value.completedAt
                ? 'This task is completed'
                : 'Mark as completed'
            "
            @mousedown.stop
          ></i>
        </div>
        <div>
          <Menu
            :value="menu.visible"
            width="200px"
            @input="menu.visible = $event"
          >
            <i
              class="ml-3 icofont-navigation-menu button-i"
              @mousedown.stop
              @click.stop="menu.visible = !menu.visible"
            ></i>
            <template v-slot:content>
              <div
                class="glass-light-3 width100 cursor-pointer"
                @mousedown.stop
                style="border-radius: 6px; overflow: auto"
              >
                <div
                  class="pa-3 glass-light-3-hover transition-all"
                  @click.stop="menu.visible = false"
                >
                  Archive
                </div>
                <div
                  class="pa-3 glass-light-3-hover transition-all"
                  @click.stop="menu.visible = false"
                >
                  Complete
                </div>
                <div
                  class="pa-3 glass-light-3-hover transition-all"
                  @click.stop="menu.visible = false"
                >
                  Start timer
                </div>
                <div
                  class="pa-3 glass-light-3-hover transition-all"
                  @click.stop="menu.visible = false"
                >
                  Stop timer
                </div>
              </div>
            </template>
          </Menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import LoadingDark from '../common/LoadingDark.vue'
  import Menu from '../common/Menu.vue'
  import {
    getFirstLineFromHtml,
    durationAsHours,
    countUncheckedFromHtml,
    countCheckedFromHtml
  } from '../../utils'

  export default {
    components: { LoadingDark, Menu },
    name: 'TaskEntry',
    props: ['value'],
    data: () => ({
      loading: {
        startStopButton: false
      },
      menu: {
        visible: false
      }
    }),
    computed: {
      activeTimeEntries() {
        return this.$store.getters.activeTimeEntries
      },
      descriptionUrls() {
        let links = []

        if (this.value.description.indexOf('<a') > -1) {
          let preLinks = this.value.description.split('href="')
          for (var i = 1; i < preLinks.length; i++) {
            links.push(preLinks[i].split('"')[0])
          }
        }
        return links
      },
      otherUrls() {
        return this.descriptionUrls.filter((url) => url != this.githubUrl)
      },
      githubUrl() {
        return this.descriptionUrls.find(
          (url) => url.indexOf('github.com') > -1
        )
      },
      totalTime() {
        const entries = this.$store.getters.timeEntries.filter(
          (entry) => entry.taskId == this.value.id
        )
        let hours = 0
        for (const entry of entries) {
          hours += durationAsHours(
            entry.start,
            entry.end ? entry.end : moment().format()
          )
        }
        return parseFloat(Math.round(hours * 100) / 100)
      },
      timeSpentColor() {
        const index = this.timeSpentPlannedRatio
        if (index > 1) return 'error'
        if (index >= 0.8) return 'warning'
        return 'success'
      },
      timeSpentPlannedRatio() {
        return this.value.plannedDurationHours && this.totalTime
          ? Math.round(
              (this.totalTime / this.value.plannedDurationHours) * 100
            ) / 100
          : 0
      },
      deadlineColor() {
        if (moment(this.value.deadline).add(-2, 'days').isBefore(moment()))
          return 'error white-text'
        if (moment(this.value.deadline).add(-7, 'days').isBefore(moment()))
          return 'warning'
        return 'primary white-text'
      },
      uncheckedCheckboxes() {
        return countUncheckedFromHtml(this.value.description)
      },
      checkedCheckboxes() {
        return countCheckedFromHtml(this.value.description)
      },
      checkboxStatus() {
        if (!this.uncheckedCheckboxes && !this.checkedCheckboxes) return ''

        return [
          this.checkedCheckboxes,
          this.uncheckedCheckboxes + this.checkedCheckboxes
        ].join(' / ')
      }
    },
    methods: {
      getFirstLineFromHtml,
      async createEntry() {
        this.loading.startStopButton = true
        await this.$store.dispatch('createTimeEntry', {
          description: this.value.description,
          projectId: this.value.projectId,
          start: moment().format(),
          taskId: this.value.id
        })
        this.loading.startStopButton = false
      },
      getActiveTimeEntry() {
        return this.activeTimeEntries.find(
          (entry) => entry.taskId == this.value.id
        )
      },
      isActive() {
        return !!this.getActiveTimeEntry()
      },
      async stopEntry() {
        this.loading.startStopButton = true
        const entry = this.getActiveTimeEntry()
        await this.$store.dispatch('updateTimeEntry', {
          id: entry.id,
          end: moment().format()
        })
        this.loading.startStopButton = false
      },
      openUrl(url) {
        window.ipc.send('openUrl', url)
      },
      formatDate(date) {
        return moment(date).format('DD.MM.YYYY HH:mm:ss')
      }
    }
  }
</script>

<style scoped>
  .deadline {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 6px;
  }
  .task {
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
  }
</style>
