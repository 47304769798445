import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import FloatingVue from 'floating-vue'
import App from './App'
import Marketing from './components/views/Marketing'
import Home from './components/views/Home'
import TasksView from './components/views/TasksView'
import Calendar from './components/views/Calendar'
import Reports from './components/views/Reports'
import Projects from './components/views/Projects'
import SignUp from './components/views/SignUp'
import SignIn from './components/views/SignIn'
import SignOut from './components/views/SignOut'
import { plugin as Slicksort } from 'vue-slicksort'
import store from './store'
import Settings from './components/views/Settings.vue'
import ResetPassword from './components/views/ResetPassword.vue'

const routeGuard = (to, from, next) => {
  if(to.meta.authRequired) {
    if(store.getters.user) {
      return next()
    } else {
      if(from.meta.authRequired || Object.keys(from.meta).length == 0) return next({ name: 'signin' })
      return next(false)
    }
  }

  if(to.meta.redirectIfAuthorized) {
    if(store.getters.user) {
      if(from.meta.redirectIfAuthorized || Object.keys(from.meta).length == 0) return next({ name: 'home' })
      return next(false)
    } else {
      return next()
    }
  }
}

const routes = [
  {
    name: 'marketing',
    path: '/',
    component: Marketing,
    meta: {},
    // beforeEnter: routeGuard
  },
  {
    name: 'home',
    path: '/home',
    component: Home,
    meta: {
      authRequired: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'tasks',
    path: '/tasks/:projectId?/:boardId?',
    component: TasksView,
    meta: {
      authRequired: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'calendar',
    path: '/calendar',
    component: Calendar,
    meta: {
      authRequired: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'reports',
    path: '/reports',
    component: Reports,
    meta: {
      authRequired: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'projects',
    path: '/projects',
    component: Projects,
    meta: {
      authRequired: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'signup',
    path: '/signup',
    component: SignUp,
    meta: {
      redirectIfAuthorized: true,
      hideSideBar: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'signin',
    path: '/signin',
    component: SignIn,
    meta: {
      redirectIfAuthorized: true,
      hideSideBar: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'signout',
    path: '/singout',
    component: SignOut,
    meta: {
      authRequired: true,
      hideSideBar: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'resetpassword',
    path: '/resetpassword',
    component: ResetPassword,
    meta: {
      redirectIfAuthorized: true,
      hideSideBar: true
    },
    beforeEnter: routeGuard
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    meta: {
      authRequired: true
    },
    beforeEnter: routeGuard
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

if (
  window.location.href.startsWith('https://') &&
  !window.location.hostname.startsWith('www')
) {
  window.location = window.location.href.replace(
    'https://',
    'https://www.'
  )
} else {
  createApp(App)
    .use(FloatingVue)
    .use(Slicksort)
    .use(store)
    .use(router).mount('#app')
}

