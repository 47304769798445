<template>
  <div
    style="position: relative; outline: none"
    @blur="$emit('input', false)"
    class="menu-wrap"
  >
    <div ref="mainSlot">
      <slot></slot>
    </div>
    <Teleport to="#app">
      <Transition>
        <div
          v-if="value && !disabled"
          style="position: fixed; z-index: 999999"
          :style="
            [
              'width:' +
                (width ? width : slotWidth ? slotWidth + 'px' : '100%'),
              'top: ' + slotPosition.top + 'px',
              'left: ' + slotPosition.left + 'px',
              slotTransition ? 'transition: all 0.2s ease' : ''
            ].join(';')
          "
        >
          <div
            class="menu"
            @click.stop
            ref="menu"
            :tabindex="$attrs.onBlur ? 0 : undefined"
            @blur="$emit('blur')"
            :style="
              'width:' + (width ? width : slotWidth ? slotWidth + 'px' : '100%')
            "
          >
            <slot name="content"></slot>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
  export default {
    name: 'Menu',
    props: ['value', 'focusMenu', 'disabled', 'width'],
    data: () => ({
      slotWidth: null,
      slotHeight: null,
      slotPosition: {
        top: 0,
        left: 0
      },
      slotTransition: false,
      resizeObserver: null,
      posTimer: null
    }),
    watch: {
      async value(val) {
        if (val) {
          if (this.focusMenu) {
            this.$refs.menu.focus()
          }
          this.resizeObserver.observe(this.$refs.mainSlot)
          this.onResize()
          this.onMove()
          await this.$nextTick()
          this.onResize()
          this.onMove()
          this.postTimerTick()

          this.$refs.menu.focus()
        } else {
          clearTimeout(this.posTimer)
          this.resizeObserver.unobserve(this.$refs.mainSlot)
          this.slotTransition = false
        }
      }
    },
    computed: {
      screenWidth() {
        return this.$store.getters.screenWidth
      },
      screenHeight() {
        return this.$store.getters.screenHeight
      }
    },
    async mounted() {
      await this.$nextTick()
      this.resizeObserver = new ResizeObserver(this.onResize)
    },
    methods: {
      onResize() {
        this.slotWidth =
          (this.$refs.mainSlot && this.$refs.mainSlot.clientWidth) || 0
        this.slotHeight =
          (this.$refs.mainSlot && this.$refs.mainSlot.clientHeight) || 0
      },
      onMove() {
        if (this.$refs.mainSlot) {
          const menuWidth =
            (this.$refs.menu && this.$refs.menu.clientWidth) || 0

          const menuHeight =
            (this.$refs.menu && this.$refs.menu.clientHeight) || 0

          const boundingRect = this.$refs.mainSlot.getBoundingClientRect()
          this.slotPosition.top = boundingRect.top + this.slotHeight
          this.slotPosition.left =
            boundingRect.left - (menuWidth - this.slotWidth) / 2

          // Outside of view right side
          if (this.slotPosition.left + menuWidth > this.screenWidth) {
            this.slotPosition.left = this.screenWidth - menuWidth
          }

          // Outside of view left side
          if (this.slotPosition.left < 0) {
            this.slotPosition.left = 0
          }

          if (this.slotPosition.top + menuHeight > this.screenHeight) {
            this.slotPosition.top = this.screenHeight - menuHeight
          }
        }
      },
      postTimerTick() {
        clearTimeout(this.posTimer)
        this.posTimer = setTimeout(() => {
          this.slotTransition = true
          this.onMove()
          this.postTimerTick()
        }, 500)
      }
    }
  }
</script>

<style scoped>
  .menu {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10000;
  }
</style>
