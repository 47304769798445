<template>
  <div class="flex justify-center">
    <div style="max-width: 314px">
      <div class="flex justify-center my-4">
        <img src="../../assets/logo.png" height="32" />
      </div>
      <div>
        <div><h4>Reset password</h4></div>
        <form>
          <Input
            :value="email"
            @input="email = $event"
            label="E-mail"
            name="email"
            :disabled="loading"
          />
          <div class="flex wrap align-center mt-4">
            <div class="xs12">
              <Button
                variant="smooth primary"
                :disabled="loading"
                class="mb-2 width100"
                @click="false"
                :loading="loading"
              >
                Reset password
              </Button>
            </div>
            <div class="xs12 text-center pt-5">
              Already have an account?
              <router-link class="ml-3" to="signin">Sign in</router-link>
            </div>
            <div class="xs12 text-center pt-5">
              Don't have an account?
              <router-link class="ml-3" to="signup">
                Create an account
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../common/Button.vue'
  import Input from '../common/Input.vue'
  export default {
    name: 'ResetPassword',
    components: { Button, Input },
    data: () => ({}),
    watch: {},
    async mounted() {}
  }
</script>

<style scoped></style>
