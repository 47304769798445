<template>
  <div>
    <div class="flex mb-4 xs12">
      <div class="xs12 flex align-center">
        <Select
          label="Project"
          :items="projectItems"
          :value="this.$route.params.projectId"
          @input="
            this.$router.push({ name: 'tasks', params: { projectId: $event } })
          "
        ></Select>
      </div>
      <div class="flex justify-end">
        <div>
          <i
            @click="$refs.createBoard.showDialog()"
            v-tooltip="'Create new board'"
            class="icofont-plus button-i mr-3"
          ></i>
        </div>
        <div>
          <i
            v-tooltip="'View archived tasks'"
            class="icofont-archive button-i"
            @click="showArchivedTasks = !showArchivedTasks"
          ></i>
        </div>
      </div>
    </div>
    <Tasks
      v-if="!showArchivedTasks && selectedProject"
      :project="selectedProject"
    ></Tasks>
    <div v-else-if="showArchivedTasks && selectedProject" class="pa-4">
      <Button class="mb-4" @click="showArchivedTasks = false">
        Close archive
      </Button>
      <ArchivedTasks :projectId="selectedProject.id"></ArchivedTasks>
    </div>
    <CreateBoardDialog
      :projectId="selectedProject?.id"
      ref="createBoard"
    ></CreateBoardDialog>
  </div>
</template>

<script>
  import Tasks from '../modules/Tasks'
  import Button from '../common/Button'
  import ArchivedTasks from '../modules/ArchivedTasks'
  import CreateBoardDialog from '../modules/CreateBoardDialog'
  import Select from '../common/Select'

  export default {
    name: 'TasksView',
    components: {
      Tasks,
      ArchivedTasks,
      Button,
      Select,
      CreateBoardDialog
    },
    props: [],
    data: () => ({
      showArchivedTasks: false,
      dragging: false
    }),
    mounted() {
      if (
        (!this.$route.params.projectId || !this.$route.params.boardId) &&
        this.projectsOrdered.length
      ) {
        let routerPayload = {
          name: this.$route.name,
          params: {}
        }

        if (!this.$route.params.projectId)
          routerPayload.params.projectId = this.projectsOrdered[0].id

        const boards = this.$store.getters.boardsOrderedWithTasks(
          this.projectsOrdered[0].id
        )
        if (!this.$route.params.boardId && boards.length)
          routerPayload.params.boardId = boards[0].id

        this.$router.push(routerPayload)
      }
    },
    computed: {
      projectItems() {
        return this.projectsOrdered.map((p) => ({ value: p.id, text: p.title }))
      },
      selectedProject() {
        return this.projectsOrdered.find(
          (project) => project.id == this.$route.params.projectId
        )
      },
      projectsOrdered() {
        return this.$store.getters.projectsOrdered
      },
      boards() {
        if (!this.project) return []
        return this.$store.getters.boardsOrderedWithTasks(this.project.id)
      }
    },
    methods: {
      startEditProject() {
        const key = 'editProjectEditor'
        this.dialogs[key].form.title.default = this.selectedProject.title
        this.dialogs[key].form.color.default = this.selectedProject.color
        this.dialogs[key].dialog = true
      },
      async dialogOk(formKey, payload) {
        this.dialogs[formKey].loading = true
        if (formKey == 'createProjectEditor') {
          await this.$store.dispatch('createProject', payload)
        } else if (formKey == 'editProjectEditor') {
          await this.$store.dispatch('updateProject', {
            id: this.selectedProject.id,
            ...payload
          })
        } else if (formKey == 'createBoardEditor') {
          await this.$store.dispatch('createBoard', {
            projectId: this.selectedProject.id,
            ...payload
          })
        }
        this.dialogs[formKey].hasChanges = false
        this.dialogs[formKey].dialog = false
        this.dialogs[formKey].loading = false
      },
      moveProject(projects) {
        this.$store.dispatch('reorderProjects', projects)
      }
    }
  }
</script>

<style scoped></style>
