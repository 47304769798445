<template>
  <div class="flex justify-center">
    <div style="max-width: 314px">
      <div>
        <div><h3>Logged out</h3></div>
        <div>
          <router-link to="signin">Sign in</router-link>
        </div>
        <div>
          <router-link to="signup">Sign up</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SignOut',
    components: {},
    data: () => ({}),
    watch: {},
    async mounted() {
      this.$store.dispatch('signout')
      this.$router.push({ name: 'signin' })
    }
  }
</script>

<style scoped></style>
