<template>
  <div>
    <Menu :value="menu" @input="menu = $event" :disabled="disabled">
      <Input
        :value="valueHold"
        @input="input($event)"
        @focus="menu = true"
        @blur="blur"
        class="width100"
        type="text"
        :name="name"
        :label="label"
        :disabled="disabled"
        :inputClass="inputClass + ' cursor-pointer'"
        :error="error"
        @clear="select(null)"
        ref="input"
        @keydown="keyDown"
        :inputStyle="
          menu ? 'border-bottom-right-radius:0;border-bottom-left-radius:0' : ''
        "
      />
      <template v-slot:content>
        <div class="content glass-light-3 black-text">
          <div
            class="pa-3"
            :style="
              navigationSelectedItem == item.value
                ? 'background-color: red'
                : ''
            "
            v-for="(item, i) of itemsFiltered"
            :key="i"
            @click="select(item)"
          >
            {{ item && item.text ? item.text : item }}
          </div>
          <div class="pa-3" v-if="valueHold && !searchResult.length">
            Create "{{ valueHold }}" +
          </div>
          <div
            style="color: rgba(0, 0, 0, 0.5)"
            class="pa-3"
            v-else-if="!items || !items.length"
          >
            No items
          </div>
        </div>
      </template>
    </Menu>
  </div>
</template>

<script>
  import Menu from './Menu.vue'
  import Input from './Input.vue'
  import Fuse from 'fuse.js'

  export default {
    name: 'Select',
    components: { Menu, Input },
    props: [
      'value',
      'label',
      'name',
      'items',
      'disabled',
      'inputClass',
      'error'
    ],
    data: () => ({
      menu: false,
      searchDelay: 10,
      searchTimer: null,
      searchResult: [],
      valueHold: '',
      navigationSelectedItem: null
    }),
    mounted() {
      this.reset()
    },
    watch: {
      value() {
        this.reset()
      }
    },
    computed: {
      isObjects() {
        return (
          this.items &&
          this.items[0] &&
          this.items[0].text !== undefined &&
          this.items[0].value !== undefined
        )
      },
      itemKeys() {
        if (!this.items || !this.items[0] || typeof this.items[0] == 'string')
          return []
        return this.items ? Object.keys(this.items[0]) : []
      },
      itemsFiltered() {
        if (!this.valueHold) return this.items
        return this.searchResult
      }
    },
    methods: {
      keyDown(e) {
        if (e.keyCode == 40) {
          let index = 0
          if (this.navigationSelectedItem) {
            index =
              this.itemsFiltered.findIndex(
                (item) => item.value == this.navigationSelectedItem
              ) + 1
          }
          this.navigationSelectedItem = this.itemsFiltered[index].value
        } else if (e.keyCode == 38) {
          let index = this.itemsFiltered.length - 1
          if (this.navigationSelectedItem) {
            index =
              this.itemsFiltered.findIndex(
                (item) => item.value == this.navigationSelectedItem
              ) - 1
          }
          this.navigationSelectedItem = this.itemsFiltered[index].value
        } else if (e.keyCode == 13) {
          let item =
            this.itemsFiltered.find(
              (item) => item.value == this.navigationSelectedItem
            ) + 1

          this.select(item)
        } else if (e.keyCode == 32) {
          // Spacebar
        }
      },
      reset() {
        if (this.isObjects) {
          const option = this.items.find((o) => o.value == this.value)
          this.valueHold = option ? option.text : ''
        } else this.valueHold = this.value
        if (this.valueHold) this.search(this.valueHold)
      },
      check() {
        return document.activeElement.classList.contains('menu-wrap')
      },
      async blur() {
        setTimeout(() => {
          this.menu = this.check() ? true : false
        }, 50)
      },
      input(val) {
        this.valueHold = val
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(() => {
          this.search(val)
        }, this.searchDelay)
      },
      search(val) {
        val = val || ''
        const options = {
          includeScore: true,
          keys: this.itemKeys,
          threshold: 1
        }
        const fuse = new Fuse(this.items ? this.items : [], options)

        this.searchResult = []

        for (const entry of fuse.search(val)) {
          this.searchResult.push(this.items[entry.refIndex])
        }
      },
      select(item) {
        this.menu = false
        this.valueHold = this.isObjects ? (item ? item.text : null) : item
        this.search(this.valueHold)
        this.$emit('input', this.isObjects ? (item ? item.value : null) : item)
      },
      focus() {
        this.$refs.input.focus()
      }
    }
  }
</script>

<style scoped>
  .content {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    outline: none;
    overflow: hidden;
  }
  .content > div {
    cursor: pointer;
    transition: all 0.15s ease;
  }
  .content > div:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
</style>
