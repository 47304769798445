<template>
  <div style="border-radius: 8px" class="glass-light-2 flex wrap">
    <div class="flex xs12">
      <div
        class="text-ellipsis"
        v-html="
          getProjectTitle(value.projectId) ||
          `<span style='font-style:italic;color:gray'>No project</span>`
        "
      ></div>
      <div class="pl-2 font-weight-bold">
        {{ getDuration(value.start) }}
      </div>
      <div style="cursor: pointer">
        <i
          @click="stopEntry"
          v-if="!loading"
          class="ml-3 button-i icofont-square"
        ></i>
        <LoadingDark v-else size="16" class="ml-3 mt-1"></LoadingDark>
      </div>
      <div>
        <i class="ml-3 button-i icofont-ui-check"></i>
      </div>
    </div>
    <div class="font-weight-bold text-ellipsis xs12">
      {{ getFirstLineFromHtml(value.description) }}
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import LoadingDark from '../common/LoadingDark.vue'
  import { getFirstLineFromHtml, durationFormat } from '../../utils'

  export default {
    components: { LoadingDark },
    name: 'ActiveTimeEntry',
    props: ['value'],
    data: () => ({
      timer: null,
      currentTime: moment().format(),
      loading: false
    }),
    mounted() {
      this.timerTick()
    },
    beforeUnmount() {
      clearTimeout(this.timer)
    },
    computed: {
      projects() {
        return this.$store.getters.projects
      }
    },
    methods: {
      getFirstLineFromHtml,
      getProjectTitle(projectId) {
        const project = this.projects.find((p) => p.id == projectId)
        return project ? project.title : ''
      },
      getDuration(startIn) {
        return durationFormat(startIn, this.currentTime)
      },
      timerTick() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.currentTime = moment().format()
          this.timerTick()
        }, 1000)
      },
      async stopEntry() {
        this.loading = true
        await this.$store.dispatch('updateTimeEntry', {
          id: this.value.id,
          end: moment().format()
        })
        this.loading = false
      }
    }
  }
</script>

<style scoped></style>
