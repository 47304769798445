<template>
  <div>
    <Tabs
      :items="boards.map((b) => ({ text: b.title, value: b.id }))"
      :value="board?.id"
      @input="
        $router.push({
          name: 'tasks',
          params: { boardId: $event, projectId: this.$route.params.projectId }
        })
      "
      @updateOrder="moveBoard"
    ></Tabs>

    <div class="flex justify-end" v-if="board">
      <div>
        <i
          v-tooltip="'Show archived tasks for this board'"
          class="icofont-archive button-i ml-3"
          @mousedown.stop
        ></i>
      </div>
      <div>
        <i
          v-tooltip="'Create new task'"
          class="icofont-plus button-i ml-3"
          @mousedown.stop
          @click="startCreateNewTask(board)"
        ></i>
      </div>
      <div>
        <i
          v-tooltip="'Board settings'"
          class="icofont-options button-i ml-3"
          @mousedown.stop
          @click="startEditBoard(board)"
        ></i>
      </div>
    </div>
    <SlickList
      v-if="board"
      :pressDelay="0"
      axis="y"
      :list="board.tasks"
      appendTo="#app"
      group="items"
      @sort-end="dragging = false"
      :transitionDuration="150"
      @update:list="moveTask(board.id, $event)"
    >
      <SlickItem
        v-for="(task, index) of board.tasks"
        :key="task.id"
        :index="index"
        @mousedown="dragging = true"
        class="board-task"
        :class="dragging ? 'cursor-grabbing' : 'cursor-grab'"
      >
        <TaskEntry
          @taskEditor="taskEditor(task)"
          @confirmCompletionDialog="confirmCompletionDialog(task)"
          @resetCompletionDialog="resetCompletionDialog(task)"
          @confirmArchiveDialog="confirmArchiveDialog(task)"
          :value="task"
        ></TaskEntry>
      </SlickItem>
    </SlickList>
    <Dialog
      v-for="(dialog, key) of dialogs"
      :key="key"
      :value="dialog.dialog"
      @close="
        () => {
          dialog.dialog = false
          dialog.hasChanges = false
        }
      "
      :persistent="dialog.loading || (dialog.changeGuard && dialog.hasChanges)"
    >
      <DataForm
        :type="dialog.type"
        :value="dialog.form"
        :loading="dialog.loading"
        :disabled="dialog.loading"
        :allowCancel="true"
        @cancel="dialog.dialog = false"
        @update="dialogOk(key, $event)"
        :title="dialog.title"
        @updateHasChanges="dialog.hasChanges = $event"
      ></DataForm>
    </Dialog>
  </div>
</template>

<script>
  import { SlickList, SlickItem } from 'vue-slicksort'
  import DataForm from '../common/DataForm.vue'
  import Dialog from '../common/Dialog.vue'
  import TaskEntry from './TaskEntry.vue'
  import Tabs from '../common/Tabs.vue'
  import moment from 'moment'
  import { getFirstLineFromHtml } from '../../utils'

  export default {
    components: {
      DataForm,
      Dialog,
      SlickList,
      SlickItem,
      TaskEntry,
      Tabs
    },
    name: 'Tasks',
    props: ['project'],
    data: () => ({
      dialogs: {
        createTaskEditor: {
          dialog: false,
          loading: false,
          title: 'Create task',
          type: 'create',
          selectedBoard: null,
          form: {
            description: {
              type: 'textarea',
              label: 'Description',
              default: '',
              validate: (val) =>
                (val && getFirstLineFromHtml(val).length > 0) ||
                'Must be atleast 1 character long'
            },
            plannedDurationHours: {
              type: 'number',
              label: 'Planned duration in hours',
              default: ''
            },
            deadline: {
              type: 'datetime',
              label: 'Deadline',
              default: ''
            },
            boardId: {
              type: 'select',
              label: 'Board',
              options: [],
              default: '',
              validate: (val) => !!val || 'A board must be selected'
            }
          }
        },
        taskEditor: {
          dialog: false,
          loading: false,
          title: 'Edit task',
          type: 'edit',
          changeGuard: true,
          hasChanges: false,
          selectedTask: null,
          form: {
            description: {
              type: 'textarea',
              label: 'Description',
              default: '',
              validate: (val) =>
                (val && getFirstLineFromHtml(val).length > 0) ||
                'Must be atleast 1 character long'
            },
            plannedDurationHours: {
              type: 'number',
              label: 'Planned duration in hours',
              default: ''
            },
            deadline: {
              type: 'datetime',
              label: 'Deadline',
              default: ''
            },
            boardId: {
              type: 'select',
              label: 'Board',
              options: [],
              default: '',
              validate: (val) => !!val || 'A board must be selected'
            }
          }
        },
        confirmCompletionDialog: {
          dialog: false,
          loading: false,
          title: "Are you sure you're done with the task?",
          type: 'yesno',
          selectedTask: null,
          form: {}
        },
        resetCompletionDialog: {
          dialog: false,
          loading: false,
          title: 'Are you sure you want to set the task as uncomplete?',
          type: 'yesno',
          selectedTask: null,
          form: {}
        },
        confirmArchiveDialog: {
          dialog: false,
          loading: false,
          title: 'Are you sure you want to archive the task?',
          type: 'yesno',
          selectedTask: null,
          form: {}
        },
        editBoardEditor: {
          dialog: false,
          loading: false,
          title: 'Edit board',
          type: 'edit',
          form: {
            title: {
              type: 'text',
              label: 'Title',
              default: '',
              validate: (val) =>
                (val && val.length > 0) || 'Must be atleast 1 character long'
            },
            githubRepoId: {
              type: 'select',
              label: 'GitHub project',
              options: ['numene/numen-services'],
              default: ''
            }
          }
        }
      },
      dragging: false
    }),
    mounted() {
      this.setDialogsOptions()
    },
    watch: {
      boards() {
        this.setDialogsOptions()
      },
      project() {
        this.setDialogsOptions()
      }
    },
    computed: {
      boards() {
        if (!this.project) return []
        return this.$store.getters.boardsOrderedWithTasks(this.project.id)
      },
      board() {
        return this.boards.find((b) => b.id == this.$route.params.boardId)
      }
    },
    methods: {
      setDialogsOptions() {
        const boardItems = this.boards.map((b) => ({
          value: b.id,
          text: b.title
        }))
        this.dialogs.taskEditor.form.boardId.options = boardItems
        this.dialogs.createTaskEditor.form.boardId.options = boardItems
      },
      async dialogOk(formKey, payload) {
        this.dialogs[formKey].loading = true
        if (formKey == 'createProjectEditor') {
          await this.$store.dispatch('createProject', payload)
        } else if (formKey == 'resetCompletionDialog') {
          await this.$store.dispatch('updateTask', {
            id: this.dialogs.resetCompletionDialog.selectedTask.id,
            completedAt: null
          })
        } else if (formKey == 'confirmCompletionDialog') {
          await this.$store.dispatch('updateTask', {
            id: this.dialogs.confirmCompletionDialog.selectedTask.id,
            completedAt: moment().format()
          })
        } else if (formKey == 'confirmArchiveDialog') {
          await this.$store.dispatch('updateTask', {
            id: this.dialogs.confirmArchiveDialog.selectedTask.id,
            archivedAt: moment().format()
          })
          // this.$store.dispatch(
          //   'reorderTasks',
          //   this.boards.find(
          //     (board) =>
          //       board.id ==
          //       this.dialogs.confirmArchiveDialog.selectedTask.boardId
          //   )
          // )
        } else if (formKey == 'taskEditor') {
          await this.$store.dispatch('updateTask', {
            id: this.dialogs.taskEditor.selectedTask.id,
            boardId: this.dialogs.taskEditor.selectedTask.boardId,
            description: payload.description,
            plannedDurationHours: payload.plannedDurationHours || 0,
            deadline: payload.deadline
          })
        } else if (formKey == 'createTaskEditor') {
          console.log(payload)
          if (!payload.plannedDurationHours) payload.plannedDurationHours = 0
          payload.projectId = this.project.id
          await this.$store.dispatch('createTask', payload)
        } else if (formKey == 'editProjectEditor') {
          await this.$store.dispatch('updateProject', {
            id: this.project.id,
            ...payload
          })
        } else if (formKey == 'editBoardEditor') {
          await this.$store.dispatch('updateBoard', {
            id: this.dialogs.editBoardEditor.selectedBoard.id,
            ...payload
          })
        }
        this.dialogs[formKey].hasChanges = false
        this.dialogs[formKey].dialog = false
        this.dialogs[formKey].loading = false
      },
      moveTask(id, tasks) {
        this.$store.dispatch('reorderTasks', {
          id,
          tasks,
          projectId: this.project.id
        })
      },
      moveBoard(boards) {
        this.$store.dispatch('reorderBoards', {
          id: this.project.id,
          boards: boards.map((b) => ({ id: b.value }))
        })
      },
      confirmCompletionDialog(task) {
        this.dialogs.confirmCompletionDialog.dialog = true
        this.dialogs.confirmCompletionDialog.selectedTask = task
      },
      resetCompletionDialog(task) {
        this.dialogs.resetCompletionDialog.dialog = true
        this.dialogs.resetCompletionDialog.selectedTask = task
      },
      confirmArchiveDialog(task) {
        this.dialogs.confirmArchiveDialog.dialog = true
        this.dialogs.confirmArchiveDialog.selectedTask = task
      },
      taskEditor(task) {
        for (const prop in task) {
          if (
            this.dialogs.taskEditor.form[prop] &&
            this.dialogs.taskEditor.form[prop].default !== undefined
          ) {
            this.dialogs.taskEditor.form[prop].default = task[prop]
          }
        }
        this.dialogs.taskEditor.dialog = true
        this.dialogs.taskEditor.selectedTask = task
      },
      startCreateNewTask(board) {
        this.dialogs.createTaskEditor.form.boardId.default = board.id
        this.dialogs.createTaskEditor.dialog = true
      },
      startEditBoard(board) {
        const key = 'editBoardEditor'
        this.dialogs[key].form.title.default = board.title
        this.dialogs[key].selectedBoard = board
        this.dialogs[key].dialog = true
      }
    }
  }
</script>
