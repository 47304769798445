<template>
  <div class="flex column height100" v-if="!loading">
    <div class="flex xs12">
      <TransitionExpandX :width="sideBarWidth + 'px'">
        <SideBar
          v-if="user && !$route.meta.hideSideBar"
          :value="showSideBar"
          :width="sideBarWidth"
        />
      </TransitionExpandX>
      <div class="xs12 pa-3 ma-3 router-wrap">
        <router-view style="overflow: auto" />
      </div>
    </div>
  </div>
</template>

<script>
  import SideBar from './components/modules/SideBar.vue'
  import TransitionExpandX from './components/transitions/TransitionExpandX.vue'
  import './assets/structure.css'
  import './assets/fonts/icofont/icofont.min.css'
  import 'floating-vue/dist/style.css'

  export default {
    name: 'App',
    components: {
      SideBar,
      TransitionExpandX
    },
    data: () => ({
      sideBarWidth: 240,
      loading: true
    }),
    async mounted() {
      window.addEventListener('resize', this.onWindowResize)
      this.onWindowResize()
      try {
        await this.$store.dispatch('init')
      } catch (err) {
        this.$router.push('signin')
      }

      this.loading = false
    },
    unmounted() {
      window.removeEventListener('resize', this.onWindowResize)
    },
    computed: {
      showSideBar() {
        return this.screenWidth > 600
      },
      screenWidth() {
        return this.$store.getters.screenWidth
      },
      user() {
        return this.$store.getters.user
      }
    },
    methods: {
      onWindowResize() {
        this.$store.dispatch('set', {
          screenWidth: window.innerWidth,
          screenHeight: window.innerHeight
        })
      }
    }
  }
</script>

<style>
  /* cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2)
      format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2)
      format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2)
      format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2)
      format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2)
      format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
      U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2)
      format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
      U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
      U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  html,
  body,
  #app {
    margin: 0;
    height: 100%;
    background-color: rgb(234, 232, 229);
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .router-wrap {
    border-radius: 10px;
    overflow: hidden;
    background-color: #faf9f7;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.5);
  }
  .glassButton {
    max-height: 42px;
    height: 42px;
    background-color: rgba(255, 255, 255, 0.04);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  .glassButtonActive {
    background-color: rgba(255, 255, 255, 0.15);
  }
  .glassButton:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
  .glass-dark-1 {
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .glass-light-1 {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .glass-light-2 {
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .glass-light-3 {
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .glass-light-3-hover:hover {
    background: rgba(255, 255, 255, 0.95);
  }
  .glass-panel {
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
  }
  .primary-text {
    color: rgb(26, 90, 139) !important;
  }
  .success-text {
    color: rgb(0, 129, 13) !important;
  }
  .disabled-text,
  .disabled-text i {
    color: rgba(255, 255, 255, 0.1);
  }
  .error {
    background-color: #ba0618;
  }
  .error-text {
    color: #d50000;
  }
  .success-text {
    color: #2f844b !important;
  }
  .warning-text {
    color: #dd7a02 !important;
  }
  .white-text {
    color: #fff;
  }
  .primary {
    background-color: rgb(26, 90, 139) !important;
  }
  .primary-glass {
    background-color: rgba(26, 89, 137, 0.08) !important;
  }
  .error {
    background-color: #d50000;
  }
  .success {
    background-color: #388e3c !important;
  }
  .warning {
    background-color: #fb8c00 !important;
  }
  .draftTag {
    position: absolute;
    right: 1px;
    top: 1px;
    font-size: 11px;
  }
  select {
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    padding: 12px;
    border: none;
    background: rgba(255, 255, 255, 0.2);
    /* box-shadow: 2px 2x 12px rgba(0, 0, 0, 0.2); */
    transition: color 0.3s ease-out;
    outline: none;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 16px top 50%, 0 0;
    background-size: 0.65em auto, 100%;
  }
  .button-i {
    color: rgba(0, 0, 0, 0.4);
    transition: all 0.15s ease;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .button-i.disabled {
    cursor: default !important;
  }
  .button-i:not(.disabled):hover {
    color: rgba(0, 0, 0, 1);
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }
  .button-i.white-text {
    color: rgba(255, 255, 255, 0.4);
    transition: all 0.15s ease;
    cursor: pointer;
  }
  .button-i:not(.disabled).white-text:hover {
    color: rgba(255, 255, 255, 1);
    text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.3);
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.15s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  .dp__menu,
  .dp__overlay {
    background-color: rgba(255, 255, 255, 0.95) !important;
  }
  .dp__range_between {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
  .v-popper__wrapper {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar-track:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
  }
  .tab {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.15s ease;
    font-weight: bold;
  }
  .tab:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .tab-active {
    color: rgba(0, 0, 0, 0.85) !important;
  }
</style>
