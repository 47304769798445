import moment from 'moment'
import config from '../config'
import store from '../store'

const getFirstLineFromHtml = (val) => {
  if (!val || val.indexOf('<') < 0) return val
  let tag = val.split('<')[1]
  tag = tag.split('>')[0]

  let tag2 = val.split('<' + tag + '>')[1]
  const result = tag2.split('</' + tag.split(' ')[0] + '>')[0]
  return result.indexOf('<') > -1 ? getFirstLineFromHtml(result) : result
}

const countUncheckedFromHtml = (val) => {
  return (val.match(/<input type="checkbox">/g) || []).length
}

const countCheckedFromHtml = (val) => {
  return (val.match(/<input type="checkbox" checked="checked">/g) || []).length
}

const sleep = (m) => new Promise(r => setTimeout(r, m))

const randomString = (length = 10) => {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const durationAsHours = (start, end) => {
  return moment
        .duration(moment(end).diff(moment(start)))
        .as('hours')
}

const durationAsSeconds = (start, end) => {
  return moment
        .duration(moment(end).diff(moment(start)))
        .as('seconds')
}

const secondsFormat = (totalSeconds) => {  
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours}:${(minutes + '').padStart(2, '0')}:${(seconds + '').padStart(2, '0')}`;
}

const durationFormat = (start, end) => {
  const totalSeconds = durationAsSeconds(start, end)
  
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = parseInt(totalSeconds % 60);
  return `${hours}:${(minutes + '').padStart(2, '0')}:${(seconds + '').padStart(2, '0')}`;
}

const api = async (options) => {
  const headers = options.headers || {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  const authToken = store.getters.authToken
  if(authToken) {
    headers.Authorization = 'Bearer ' + authToken
  }

  if(!config.apiUrl.endsWith('/')) config.apiUrl += '/'

  const response = await fetch(config.apiUrl + options.url, {
    method: options.method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(options.body)
  })

  const responseJson = await response.json()

  console.log('Result ' + options.method + ' ' + options.url, responseJson)

  if(response.status > 399) {
    throw responseJson
  }
  
  return responseJson
}

const formatDate = (date) => {
  return moment(date).format('DD.MM.YYYY')
}

const formatDateTime = (date) => {
  return moment(date).format('DD.MM.YYYY HH:mm:ss')
}

function isArray(obj) {
  return obj && obj.constructor && obj.constructor == Array
}

export {
  sleep,
  randomString,
  getFirstLineFromHtml,
  durationFormat,
  durationAsHours,
  durationAsSeconds,
  secondsFormat,
  api,
  countUncheckedFromHtml,
  countCheckedFromHtml,
  formatDate,
  formatDateTime,
  isArray
}