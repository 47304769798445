<template>
  <RouterLink
    class="pl-3 ma-3"
    :class="
      $route.name == route || (route == '/' && $route.name == 'home')
        ? 'itemActive'
        : ''
    "
    :to="{ name: route }"
  >
    <i :class="icon"></i>
    <div class="ml-3">{{ text }}</div>
  </RouterLink>
</template>

<script>
  export default {
    name: 'SideBarLink',
    components: {},
    props: ['route', 'text', 'icon'],
    data: () => ({})
  }
</script>
