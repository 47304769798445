<template>
  <div :class="label ? 'pt-5' : ''">
    <div style="position: relative">
      <input
        :value="valueHold"
        @input.stop="onInput"
        class="focus width100 input-effect"
        :class="
          (valueHold ? 'has-content ' : '') + (inputClass ? inputClass : '')
        "
        :type="type"
        :name="name"
        @focus="$emit('focus', $event)"
        @blur="blur"
        @click="$emit('clickInput', $event)"
        @submit.stop
        :readonly="readonly"
        :disabled="disabled"
        :style="inputStyle ? inputStyle : ''"
        ref="input"
      />
      <label
        :class="disabled ? 'disabled' : ''"
        v-if="label"
        v-html="label"
      ></label>
      <div
        class="flex column justify-center"
        style="position: absolute; right: 6px; top: 0; height: 100%"
      >
        <slot></slot>
        <i v-if="valueHold" @click.stop="clear" class="icofont-close mb-1"></i>
      </div>
    </div>
    <div class="error-text ml-2 mt-2" v-if="error">
      <i class="icofont-error mr-2"></i>{{ error }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Input',
    props: [
      'value',
      'type',
      'label',
      'name',
      'readonly',
      'disabled',
      'inputStyle',
      'inputClass',
      'error'
    ],
    data: () => ({
      valueHold: '',
      disableValueWatch: false
    }),
    mounted() {
      this.valueHold = this.value
    },
    watch: {
      value(val) {
        if (!this.disableValueWatch) this.valueHold = val
      }
    },
    methods: {
      blur(ev) {
        this.$emit('blur', ev)
      },
      async onInput(event) {
        this.valueHold = event.target.value
        this.disableValueWatch = true
        this.$emit('input', this.valueHold)
        await this.$nextTick()
        this.disableValueWatch = false
      },
      async clear() {
        if (this.$attrs.onClear) return this.$emit('clear')
        this.valueHold = ''
        this.disableValueWatch = true
        this.$emit('input', '')
        await this.$nextTick()
        this.disableValueWatch = false
      },
      focus() {
        this.$refs.input.focus()
      }
    }
  }
</script>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    padding: 8px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    /* box-shadow: 2px 2x 12px rgba(0, 0, 0, 0.2); */
    transition: all 0.3s ease-out;
    outline: none;
  }
  input:disabled {
    background: rgba(0, 0, 0, 0.06);
  }
  .white-text input {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.7);
  }
  .black-text input {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.7);
  }
  .icofont-close,
  .black-text .icofont-close:before {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.15s ease;
  }
  .icofont-close:hover,
  .black-text .icofont-close:before {
    color: rgba(0, 0, 0, 0.8);
  }
  .white-text .icofont-close {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .white-text .icofont-close:hover {
    color: rgba(255, 255, 255, 0.8) !important;
  }
  .input-effect ~ label {
    position: absolute;
    left: 8px;
    width: calc(100% - 16px);
    top: 7px;
    color: rgba(0, 0, 0, 0.45);
    transition: 0.3s;
    z-index: 1;
    letter-spacing: 0.5px;
    pointer-events: none;
  }
  .input-effect ~ label.disabled {
    color: rgba(0, 0, 0, 0.18);
  }
  .input-effect:focus ~ label,
  .has-content.input-effect ~ label {
    top: -18px;
    left: 1px;
    font-size: 12px;
    color: #000000;
    transition: 0.3s;
  }
  .white-text .input-effect ~ label {
    color: rgba(255, 255, 255, 0.45);
  }
  .black-text .input-effect ~ label {
    color: rgba(0, 0, 0, 0.45);
  }
  .input-effect.white-text:focus ~ label,
  .has-content.white-text.input-effect ~ label {
    color: #ffffff;
  }
</style>
