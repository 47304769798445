<template>
  <div class="flex">
    <div class="flex xs8">
      <h3>ReportDisplay</h3>
    </div>
    <div class="flex xs4 justify-end">
      <Button variant="smooth primary" class="ma-5" @click="downloadPdf">
        Download PDF <i class="icofont-download ml-2"></i>
      </Button>
    </div>
  </div>
  <table ref="table">
    <thead>
      <tr>
        <th>Date</th>
        <th>Project</th>
        <th>Hours</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="timeEntry in combinedTimeEntries" :key="timeEntry.id">
        <td>{{ timeEntry.date }}</td>
        <td>{{ getProjectName(timeEntry.projectId) }}</td>
        <td>
          {{ hoursDisplay(timeEntry.totalTime) }}
        </td>
        <td>{{ getFirstLineFromHtml(timeEntry.description) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import {
    getFirstLineFromHtml,
    durationAsHours,
    formatDate
  } from '../../utils'
  import Button from '../common/Button.vue'
  import { jsPDF } from 'jspdf'

  export default {
    name: 'ReportDisplay',
    components: { Button },
    props: ['timeEntries', 'fileName'],
    computed: {
      projects() {
        return this.$store.getters.projects
      },
      combinedTimeEntries() {
        const combinedTimeEntries = []

        for (const timeEntry of this.timeEntries) {
          if (!timeEntry.end) continue

          const existingEntryIndex = combinedTimeEntries.findIndex((entry) => {
            return (
              this.formatDate(timeEntry.start) == entry.date &&
              timeEntry.projectId == entry.projectId &&
              this.getFirstLineFromHtml(timeEntry.description) ==
                entry.description
            )
          })

          if (existingEntryIndex > -1) {
            combinedTimeEntries[existingEntryIndex].totalTime +=
              durationAsHours(timeEntry.start, timeEntry.end)
          } else {
            combinedTimeEntries.push({
              date: this.formatDate(timeEntry.start),
              projectId: timeEntry.projectId,
              description: this.getFirstLineFromHtml(timeEntry.description),
              totalTime: durationAsHours(timeEntry.start, timeEntry.end)
            })
          }
        }
        return combinedTimeEntries
      }
    },
    methods: {
      getProjectName(projectId) {
        return this.projects.find((p) => p.id == projectId).title
      },
      hoursDisplay(hours) {
        return (Math.round(hours * 100) / 100 + '').replaceAll('.', ',')
      },
      async downloadPdf() {
        const pdf = new jsPDF({
          unit: 'px'
        })

        let rowsHtml = ''
        let totalTime = 0

        for (const entry of this.combinedTimeEntries) {
          totalTime += entry.totalTime

          rowsHtml += `
            <tr>
              <td>
                <div style="padding: 1px">
                  ${entry.date}
                </div>
              </td>
              <td>
                <div style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding: 1px">
                  ${this.getProjectName(entry.projectId)}
                </div>
              </td>
              <td>
                <div style="padding: 1px">
                  ${this.hoursDisplay(entry.totalTime)}
                </div>
              </td>
              <td>
                <div style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding: 1px">
                  ${entry.description}
                </div>
              </td>
            </tr>
          `
        }

        await pdf.html(`
          <div style="font-size: 8px; width: 440px;font-family: Helvetica; padding: 12px">
            <div style="font-size: 16px; padding: 2px">Time entries</div>
            <div style="font-size: 12px; padding: 2px">
              Total time: ${Math.round(totalTime * 100) / 100} hours
            </div>
            <table>
              <tr>
                <th style="text-align: left">Date</th>
                <th style="text-align: left">Project</th>
                <th style="text-align: left">Hours</th>
                <th style="text-align: left">Description</th>
              </tr>
              ${rowsHtml}
            </table>
          </div>
        `)
        pdf.save(this.fileName)
      },
      formatDate,
      getFirstLineFromHtml,
      durationAsHours
    }
  }
</script>

<style scoped>
  th {
    text-align: left;
  }
</style>
