<template>
  <slot
    v-for="item in currentItems"
    :key="item[itemId]"
    v-bind:item="item"
  ></slot>
</template>

<script>
  export default {
    name: 'Pagination',
    props: ['items', 'itemId', 'page', 'pageSize'],
    emits: ['updateNumberOfPages'],
    data: () => ({}),
    watch: {
      items() {
        this.$emit('updateNumberOfPages', this.numberOfPages)
      },
      pageSize() {
        this.$emit('updateNumberOfPages', this.numberOfPages)
      }
    },
    mounted() {
      this.$emit('updateNumberOfPages', this.numberOfPages)
    },
    computed: {
      currentItems() {
        return this.items.slice(
          (this.page - 1) * this.pageSize,
          this.page * this.pageSize
        )
      },
      numberOfPages() {
        return Math.ceil(this.items.length / this.pageSize)
      }
    }
  }
</script>

<style scoped></style>
