<template>
  <div
    class="position-relative"
    :style="'width: ' + size + 'px; height: ' + size + 'px'"
  >
    <div class="position-absolute">
      <div
        class="loading"
        :style="'width: ' + size + 'px; height: ' + size + 'px'"
      ></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    props: {
      size: {
        default: 24
      }
    }
  }
</script>

<style scoped>
  .loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
</style>
