<template>
  <button
    :disabled="disabled"
    :class="variant ? variant : 'standard'"
    class="flex justify-center align-center"
    :type="type == 'submit' ? type : 'button'"
    ref="button"
    :style="buttonWidth && loading ? 'min-width: ' + buttonWidth + 'px' : ''"
  >
    <slot v-if="!loading"></slot>
    <LoadingDark v-else></LoadingDark>
  </button>
</template>

<script>
  import LoadingDark from './LoadingDark.vue'
  export default {
    components: { LoadingDark },
    name: 'Button',
    props: ['variant', 'loading', 'disabled', 'type'],
    data: () => ({
      animationRunning: false,
      buttonWidth: 0
    }),
    mounted() {
      this.buttonWidth = this.$refs.button.offsetWidth
    }
  }
</script>

<style scoped>
  button {
    white-space: nowrap;
    transition: all 0.15s ease;
  }
  button:disabled {
    background-color: rgba(0, 0, 0, 0.15) !important;
    color: rgba(0, 0, 0, 0.35) !important;
    cursor: default;
  }
  .text {
    background: none;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  .text:active,
  .text:disabled {
    background: none !important;
    position: relative;
    top: 2px;
    left: 2px;
  }
  .standard,
  .round {
    border-radius: 4px;
    height: 42px;
    font-size: 20px;
    padding: 8px;
    transition: all 0.15s ease;
    background-color: rgba(255, 255, 255, 0.38);
    color: #333111;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    font-weight: bold;
    cursor: pointer;
  }
  button.small {
    height: 36px;
    font-size: 18px;
  }
  .round {
    border-radius: 50%;
    min-width: 42px;
    height: 42px;
  }
  .round.small {
    min-width: 36px;
  }
  .standard:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000;
  }
  button.primary:not(:disabled) {
    background-color: #1a5989 !important;
    color: white !important;
  }
  .smooth {
    font-family: Roboto;
    color: black;
    border-radius: 6px;
    padding: 8px;
    transition: all 0.15s ease;
    cursor: pointer;
    font-size: 18px;
    background-color: #ddd;
    box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 3px 0px;
  }
  .smooth.error {
    background-color: #ba0618;
    color: white;
  }
  .smooth:disabled {
    box-shadow: none;
  }
  .smooth:active:not(:disabled) {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 1px 0px;
    position: relative;
    top: 2px;
    left: 2px;
  }
  .smooth.primary {
    background-color: rgba(21, 72, 111, 0.9);
  }
</style>
