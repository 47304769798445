<template>
  <div class="flex wrap">
    <div class="xs12 mb-2" v-for="task of archivedTasks" :key="task.id">
      <TaskEntry class="glass-light-1" :value="task"></TaskEntry>
    </div>
  </div>
</template>

<script>
  import TaskEntry from './TaskEntry.vue'
  export default {
    name: 'ArchivedTasks',
    components: { TaskEntry },
    props: ['projectId'],
    computed: {
      archivedTasks() {
        return this.$store.getters.archivedTasks(this.projectId)
      }
    }
  }
</script>

<style scoped></style>
