<template>
  <SlickList
    :pressDelay="0"
    axis="x"
    lockAxis="x"
    :list="items"
    appendTo="#app"
    class="flex pb-4"
    @sort-end="dragging = false"
    :transitionDuration="150"
    @update:list="moveItem"
  >
    <SlickItem
      v-for="(item, index) of items"
      :key="item.value"
      :index="index"
      style="min-width: 120px"
      @mousedown="dragging = true"
      :class="dragging ? 'cursor-grabbing' : 'cursor-grab'"
    >
      <div class="flex align-center justify-center noselect ma-2">
        <h4
          @click="$emit('input', item.value)"
          @mousedown.stop
          :class="item.value == value ? 'primary-text' : ''"
          class="cursor-pointer"
        >
          {{ item.text }}
        </h4>
      </div>
    </SlickItem>
  </SlickList>
</template>

<script>
  import { SlickList, SlickItem } from 'vue-slicksort'
  export default {
    name: 'Tabs',
    props: ['items', 'value'],
    components: { SlickList, SlickItem },
    data: () => ({
      dragging: false
    }),
    methods: {
      moveItem(items) {
        this.$emit('updateOrder', items)
      }
    }
  }
</script>

<style scoped></style>
