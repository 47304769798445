<template>
  <div>
    <div style="height: calc(100% - 6px)">
      <div class="pb-6 flex align-end">
        <div class="xs2 mr-4">
          <Select
            label="Project"
            :items="projectItems"
            :value="filters.projectId"
            @input="filters.projectId = $event"
          ></Select>
        </div>
        <div class="xs5 mr-4">
          <DateTimePicker
            :range="true"
            :value="[filters.start, filters.end]"
            label="From - To"
            @input="updateTimeFilter"
          ></DateTimePicker>
        </div>
        <div class="xs2 flex align-center nowrap">
          Total time: {{ totalHours }}
        </div>
        <div class="xs3 flex justify-end">
          <Button
            @click="dialogs.pdf.dialog = true"
            class="mr-4 small"
            variant="smooth primary"
          >
            Export <i class="icofont-file-pdf ml-2"></i>
          </Button>
        </div>
      </div>
      <div class="flex wrap font-weight-bold">
        <div class="xs12 flex">
          <div class="xs4">
            <div class="mr-2 button-i text-ellipsis" @click="sort('start')">
              Start - End
              <i
                v-if="sortData.key == 'start'"
                :class="
                  'icofont-rounded-' + (sortData.ascending ? 'up' : 'down')
                "
              ></i>
            </div>
          </div>
          <div class="xs1">
            <div
              class="mx-2 button-i text-ellipsis"
              @click="sort('amountHours', true)"
            >
              Hours
              <i
                v-if="sortData.key == 'amountHours'"
                :class="
                  'icofont-rounded-' + (sortData.ascending ? 'up' : 'down')
                "
              ></i>
            </div>
          </div>
          <div class="xs3">
            <div
              class="mx-2 button-i text-ellipsis"
              @click="sort('description', true)"
            >
              Description
              <i
                v-if="sortData.key == 'description'"
                :class="
                  'icofont-rounded-' + (sortData.ascending ? 'up' : 'down')
                "
              ></i>
            </div>
          </div>
          <div class="xs2">
            <div class="mx-2 button-i text-ellipsis" @click="sort('projectId')">
              Project
              <i
                v-if="sortData.key == 'projectId'"
                :class="
                  'icofont-rounded-' + (sortData.ascending ? 'up' : 'down')
                "
              ></i>
            </div>
          </div>
          <div class="xs2">
            <div class="mx-2 button-i text-ellipsis disabled">Actions</div>
          </div>
        </div>
      </div>
      <div
        class="xs12 flex wrap pr-4"
        style="
          overflow: auto;
          max-height: calc(100% - 154px);
          min-height: calc(100% - 154px);
        "
        ref="pageContainer"
      >
        <div class="xs12">
          <Pagination
            @updateNumberOfPages="numberOfPages = $event"
            :items="timeEntries"
            key="itemId"
            :pageSize="pageSize"
            :page="page"
          >
            <template v-slot="{ item }">
              <ReportEntry :value="item"></ReportEntry>
            </template>
          </Pagination>
        </div>
      </div>
      <div class="flex xs12 justify-center py-3" v-if="numberOfPages > 1">
        <Button class="mr-2" variant="round" @click.stop="switchPage(-1)">
          <i class="icofont-curved-left"></i>
        </Button>
        <Button
          class="mr-2"
          variant="round"
          :class="page == pageNumber ? 'primary' : ''"
          v-for="pageNumber in numberOfPages"
          :key="pageNumber"
          @click="page = pageNumber"
        >
          {{ pageNumber }}
        </Button>
        <Button class="ml-2" variant="round" @click.stop="switchPage(1)">
          <i class="icofont-curved-right"></i>
        </Button>
      </div>
    </div>
    <Dialog
      :value="dialogs.pdf.dialog"
      width="90%"
      @close="dialogs.pdf.dialog = false"
    >
      <ReportDisplay
        :timeEntries="timeEntries"
        :fileName="pdfFileName"
      ></ReportDisplay>
    </Dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  import Select from '../common/Select.vue'
  import DateTimePicker from '../common/DateTimePicker.vue'
  import ReportEntry from '../modules/ReportEntry.vue'
  import Pagination from '../common/Pagination.vue'
  import Button from '../common/Button.vue'
  import Dialog from '../common/Dialog.vue'
  import ReportDisplay from '../modules/ReportDisplay.vue'
  import {
    getFirstLineFromHtml,
    durationAsSeconds,
    secondsFormat
  } from '../../utils'

  export default {
    name: 'Reports',
    components: {
      DateTimePicker,
      Select,
      ReportEntry,
      Pagination,
      Button,
      Dialog,
      ReportDisplay
    },
    data: () => ({
      dialogs: {
        pdf: {
          dialog: false
        }
      },
      filters: {
        start: moment().startOf('month').format(),
        end: moment().endOf('month').format(),
        projectId: null
      },
      sortData: {
        key: 'start',
        ascending: false,
        isReversed: false
      },
      loading: {},
      numberOfPages: 0,
      pageSize: 10,
      page: 1
    }),
    watch: {
      '$store.getters.screenHeight'() {
        this.updatePageSize()
      }
    },
    mounted() {
      this.updatePageSize()
    },
    computed: {
      timeEntries() {
        return this.$store.getters.timeEntries
          .filter((entry) => {
            let valid = true
            if (
              moment(entry.start).isBefore(moment(this.filters.start)) ||
              moment(entry.start).isAfter(moment(this.filters.end))
            ) {
              valid = false
            }
            if (
              this.filters.projectId &&
              entry.projectId != this.filters.projectId
            ) {
              valid = false
            }
            return valid
          })
          .sort((a, b) => {
            let orderVals = [1, -1]
            if (this.sortData.ascending) orderVals.reverse()
            if (this.sortData.isReversed) orderVals.reverse()

            let aVal = a[this.sortData.key]
            let bVal = b[this.sortData.key]

            if (this.sortData.key == 'projectId') {
              aVal = this.projects.find((p) => p.id == aVal).title
              bVal = this.projects.find((p) => p.id == bVal).title
            } else if (this.sortData.key == 'description') {
              aVal = getFirstLineFromHtml(aVal)
              bVal = getFirstLineFromHtml(bVal)
            }

            return aVal < bVal ? orderVals[0] : orderVals[1]
          })
      },
      projects() {
        return this.$store.getters.projects
      },
      projectItems() {
        return this.projects.map((p) => ({ value: p.id, text: p.title }))
      },
      totalHours() {
        let total = 0
        for (const timeEntry of this.timeEntries) {
          total += durationAsSeconds(
            timeEntry.start,
            timeEntry.end || moment().format()
          )
        }
        return (
          secondsFormat(total) +
          ' (' +
          Math.round((total / 60 / 60) * 100) / 100 +
          ')'
        )
      },
      pdfFileName() {
        let timeRangeText = [
          this.filters.projectId
            ? this.getProjectName(this.filters.projectId)
            : ''
        ]

        let isFullMonth =
          moment(this.filters.start)
            .startOf('month')
            .isSame(this.filters.start, 'day') &&
          moment(this.filters.start)
            .endOf('month')
            .isSame(this.filters.end, 'day')

        let isFullYear =
          moment(this.filters.start)
            .startOf('year')
            .isSame(this.filters.start, 'day') &&
          moment(this.filters.start)
            .endOf('year')
            .isSame(this.filters.end, 'day')

        if (isFullMonth) {
          timeRangeText.push(moment(this.filters.start).format('MMMM YYYY'))
        } else if (isFullYear) {
          timeRangeText.push(moment(this.filters.start).format('YYYY'))
        }
        console.log('timeRangeText', timeRangeText, isFullMonth, isFullYear)

        return timeRangeText.filter((part) => !!part).join(' ')
      }
    },
    methods: {
      getProjectName(projectId) {
        return this.projects.find((p) => p.id == projectId).title
      },
      updatePageSize() {
        this.pageSize = Math.floor(
          (this.$store.getters.screenHeight - 72 - 152) / 53
        )
        if (this.pageSize < 1) this.pageSize = 1
        if (this.page > this.numberOfPages) this.page = this.numberOfPages
      },
      updateTimeFilter(data) {
        this.filters.start = data[0]
        this.filters.end = data[1]
      },
      sort(key, reversed) {
        this.sortData.isReversed = reversed
        if (this.sortData.key == key) {
          this.sortData.ascending = !this.sortData.ascending
        } else {
          this.sortData.key = key
          this.sortData.ascending = false
        }
      },
      switchPage(pageChange) {
        this.page += pageChange
        if (this.page < 1) this.page = 1
        else if (this.page > this.numberOfPages) this.page = this.numberOfPages
      }
    }
  }
</script>

<style scoped></style>
