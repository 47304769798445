<template>
  <div>
    <div class="glass-panel pa-3 ma-3">E-mail / Account Info</div>
    <div class="glass-panel pa-3 ma-3">SignIn methods</div>
    <div class="glass-panel pa-3 ma-3">Change password (If available)</div>
    <div class="glass-panel pa-3 ma-3">Payment info</div>
    <div class="glass-panel pa-3 ma-3">Subscription info</div>
  </div>
</template>

<script>
  export default {
    name: 'Settings',
    components: {},
    data: () => ({}),
    watch: {},
    mounted() {},
    computed: {},
    methods: {}
  }
</script>

<style scoped></style>
